import React from 'react';
import { useAuth } from '@clerk/clerk-react';
import PublicRoutes from './routes/publicRoutes';
import ProtectedRoutes from './routes/protectedRoutes';

const Routing: React.FC = () => {
  const { isSignedIn, isLoaded } = useAuth();

  return isLoaded ? isSignedIn ? <ProtectedRoutes /> : <PublicRoutes /> : null;
};

export default Routing;
