import { useSignIn } from '@clerk/clerk-react';
import { useForm } from 'react-hook-form';
import { InputField } from '../../components/shared/ui/InputField';
import { Icons } from '../../helpers/icons';
import { Button } from '../../components/shared/ui/Button';
import { loginResolver } from '../../validators/AuthValidators';
import { useState, useEffect } from 'react';

interface FormValues {
  email: string;
  password: string;
}

const Login: React.FC = () => {
  const { signIn, setActive } = useSignIn();
  const [isError, setError] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isFormFilled, setIsFormFilled] = useState<boolean>(false);
  const [showEmailError, setShowEmailError] = useState<boolean>(false);
  const [showPasswordError, setShowPasswordError] = useState<boolean>(false);
  const [debouncedEmail, setDebouncedEmail] = useState<string>('');
  const [debouncedPassword, setDebouncedPassword] = useState<string>('');

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    watch,
    trigger,
    clearErrors,
  } = useForm<FormValues>({
    resolver: loginResolver,
    mode: 'onChange',
  });

  const email = watch('email');
  const password = watch('password');

  useEffect(() => {
    if (email && password) {
      setIsFormFilled(true);
    } else {
      setIsFormFilled(false);
    }
  }, [email, password]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (debouncedEmail) {
        trigger('email').then(() => {
          setShowEmailError(true);
        });
      }
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [debouncedEmail, trigger]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (debouncedPassword) {
        trigger('password').then(() => {
          setShowPasswordError(true);
        });
      }
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [debouncedPassword, trigger]);

  const handleFieldChange = (field: keyof FormValues, value: string) => {
    setValue(field, value.trim(), { shouldValidate: false });
    if (field === 'email') {
      setDebouncedEmail(value);
      setShowEmailError(false);
    } else if (field === 'password') {
      setDebouncedPassword(value);
      setShowPasswordError(false);
    }
    setError(false);
    clearErrors(field);
  };

  const onSubmit = async (data: FormValues) => {
    setLoading(true);
    try {
      const signInResponse = await signIn?.create({
        identifier: data.email,
        password: data.password,
      });

      if (signInResponse?.status === 'complete') {
        if (setActive) {
          await setActive({ session: signInResponse.createdSessionId });
        }
      } else {
        console.error('Login incomplete', signInResponse);
      }
    } catch (error) {
      setLoading(false);
      setError(true);
    }
    setLoading(false);
  };

  return (
    <div className="content-container bg-[#F9F9F9]">
      <div className="bg-white px-4 py-6 md:p-8 rounded-lg shadow-md md:min-w-[500px] min-w-full  border border-[#C9CBCD]">
        <h2 className="text-xl md:text-3xl font-bold md:mt-10 mb-1 tracking-wider">
          Hi, Welcome Back!
        </h2>
        <h1 className="text-[#3F3F3F] md:text-base text-sm mb-6 mt-4">
          Please enter your login information below.
        </h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-3">
            <InputField
              errorMsg={showEmailError ? errors.email?.message : undefined}
              register={register}
              name="email"
              placeholder="Ex.email@domain.com"
              Icon={Icons.Mail}
              autoCompleteValue="email"
              onFocus={() => setError(false)}
              onInput={e => handleFieldChange('email', e.currentTarget.value)}
            />
            <InputField
              errorMsg={
                showPasswordError ? errors.password?.message : undefined
              }
              register={register}
              name="password"
              type="password"
              placeholder="Password"
              autoCompleteValue="password"
              onChange={e => handleFieldChange('password', e.target.value)}
              onInput={e =>
                handleFieldChange('password', e.currentTarget.value)
              }
              Icon={Icons.Password}
              onFocus={() => setError(false)}
            />
          </div>
          <div className="flex w-full justify-end mt-2">
            <a
              href="/reset-password"
              className="text-[#0475BC] md:text-sm text-xs"
            >
              Forgot password?
            </a>
          </div>
          {isError && (
            <p className="my-3 text-red-10">
              Invalid email or password. Please try again.
            </p>
          )}
          <div className={`${isLoading ? 'mt-4' : 'mt-8'}`}>
            <Button
              btnType="signUp"
              type="submit"
              disabled={!isFormFilled}
              fullWidth
              variant="primary"
              label="Sign in"
              size="xl"
              loading={isLoading}
            />
          </div>
          <div className="mt-4 text-xs md:text-sm text-center">
            <span className="text-gray-600"> Don’t have an account? </span>
            <a
              href="/signup"
              className="text-etsyPrimary font-semibold underline"
            >
              Sign up
            </a>

            {/* <div className="cursor-pointer" onClick={handleGooglelogin}>
              Google kfjksdlafjkdsj
            </div> */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
