import React from 'react';

import Loader from '../../components/shared/layout/Loader';
import Card from '../../components/shared/ui/Card';
import { Button } from '../../components/shared/ui/Button';
import { Icons } from '../../helpers/icons';
import { useNavigate } from 'react-router-dom';
import {
  axiosEtsyInstance,
  useAxiosInterceptors,
} from '../../api/apiInstances';
import useGenericGetQuery from '../../CustomHooks/useQuery';
import { formatDate } from '../../helpers/utils';

export interface SessionHistoryItemProps {
  id: string;
  session_id: string;
  title: string;
  updated_at: string;
  index: number;
  is_duplicated?: boolean;
  is_original?: boolean;
  img_url?: string;
  name?: string;
  is_completed?: boolean;
}

const SessionHistoryItem: React.FC<SessionHistoryItemProps> = ({
  id,
  session_id,
  title,
  updated_at,
  index,
  is_duplicated,
  is_original,
  img_url,
  name,
  is_completed,
}) => {
  useAxiosInterceptors();
  const navigate = useNavigate();
  const handleDuplicate = () => {
    const encodedSessionId = btoa(session_id);
    const encodedLocationId = btoa(id);
    navigate(
      `/home?sessionId=${encodedSessionId}&listingId=${encodedLocationId}&title=${title}&duplicate=true`,
      {
        replace: true,
      }
    );
  };
  const handleDuplicateView = () => {
    const encodedSessionId = btoa(session_id);
    const encodedLocationId = btoa(id);

    navigate(
      `/home?sessionId=${encodedSessionId}&listingId=${encodedLocationId}&title=${title}`,
      {
        replace: true,
      }
    );
  };
  let label = 'View';
  if (title === 'UNNAMED' && is_original) {
    is_duplicated = true;
  }
  if (!is_completed) {
    label = 'Continue';
  }

  return (
    <div className="md:w-full border shadow-[0px_0px_12px_0px_rgba(74,131,184,0.12)] md:rounded-2xl rounded-xl bg-white md:p-6 p-3 mb-4 relative">
      {is_original ? (
        <div className="absolute top-0 right-0 bg-etsyPrimary text-white text-xs px-7  py-1 md:rounded-tr-2xl rounded-tr-xl md:rounded-bl-2xl rounded-bl-xl">
          Original
        </div>
      ) : (
        <div className="absolute top-0 right-0 bg-[#FAE8DE] text-etsyPrimary text-xs font-semibold px-5 py-1 md:rounded-tr-2xl rounded-tr-xl md:rounded-bl-2xl rounded-bl-xl">
          Duplicated
        </div>
      )}
      <div className="flex justify-between items-center my-3">
        <div className="flex md:flex-row flex-col md:items-center">
          {img_url ? (
            <img
              src={img_url}
              alt={title}
              className="w-16 h-16 object-contain rounded-md mr-4"
            />
          ) : (
            <Icons.HistoryPlaceholder className="w-16 h-16 mr-4 text-gray-300" />
          )}
          <div>
            <div className="font-semibold md:text-lg md:max-w-[400px] text-sm text-[#3f3f3f] mr-5">
              {name && name !== 'Untitled'
                ? name
                : title !== 'UNNAMED'
                  ? title
                  : 'Uncompleted Listing'}
            </div>
            <div className="flex flex-row items-center md:mt-1 mt-2">
              <Icons.Clock width={16} className="mr-1" />
              <p className="text-gray-600 text-sm">{formatDate(updated_at)}</p>
            </div>
          </div>
        </div>
        <div className="flex flex-row gap-2">
          <Button
            label={label}
            variant="primary"
            className={`py-2 md:px-10 px-5 rounded-sm border-etsyPrimary text-etsyPrimary md:text-base text-sm history-item-btn-${index}`}
            onClick={handleDuplicateView}
          />
          <Button
            label={'Duplicate'}
            color="primary"
            disabled={is_duplicated || !is_completed}
            className={`py-2 md:px-10 px-5 rounded-sm  md:text-base text-sm history-item-btn-${index} ${
              is_duplicated || !is_completed
                ? 'bg-[#808080] text-[#B7B7B7]'
                : 'border-etsyPrimary border bg-white text-etsyPrimary'
            }`}
            onClick={handleDuplicate}
          />
        </div>
      </div>
    </div>
  );
};

const SessionHistory: React.FC = () => {
  useAxiosInterceptors();

  const {
    data: listings,
    isLoading: loadingListings,
    isError: error,
  } = useGenericGetQuery<SessionHistoryItemProps[]>(
    '/listing',
    axiosEtsyInstance
  );

  return (
    <div className="pb-5 w-full">
      <Card
        heading="History"
        subHeading="Recently generated listings will show here"
      >
        {loadingListings && (
          <div className="flex items-center justify-center">
            <Loader />
          </div>
        )}

        {error && <p>Error loading listings</p>}

        {!loadingListings && !error && (
          <>
            {listings?.length ? (
              listings.map(
                (
                  {
                    id,
                    title,
                    updated_at,
                    session_id,
                    is_duplicated,
                    is_original,
                    img_url,
                    name,
                    is_completed,
                  },
                  index
                ) => (
                  <SessionHistoryItem
                    key={id}
                    id={id}
                    index={index}
                    title={title}
                    session_id={session_id}
                    updated_at={updated_at}
                    is_duplicated={is_duplicated}
                    is_original={is_original}
                    img_url={img_url}
                    name={name}
                    is_completed={is_completed}
                  />
                )
              )
            ) : (
              <p>No listings generated yet</p>
            )}
          </>
        )}
      </Card>
    </div>
  );
};

export default SessionHistory;
