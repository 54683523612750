import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface KeywordsState {
  keywords: string[] | [];
}

const initialState: KeywordsState = {
  keywords: [],
};

const keywordsSlice = createSlice({
  name: 'keywords',
  initialState,
  reducers: {
    setKeywords: (state, action: PayloadAction<string[] | []>) => {
      state.keywords = action.payload;
    },
  },
});

export const { setKeywords } = keywordsSlice.actions;
export default keywordsSlice.reducer;
