import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AuthState {
  token: string;
  credentials: Credentials | null;
  user: User | null;
  email: string;
}

interface User {
  id: string;
  name: string;
}

interface Credentials {
  username: string;
  password: string;
}
const initialState: AuthState = {
  token: '',
  credentials: null,
  user: null,
  email: '',
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User | null>) => {
      state.user = action.payload;
    },
    setCredentials: (state, action: PayloadAction<Credentials | null>) => {
      state.credentials = action.payload;
    },
    setAuthToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    logOut: state => {
      state.token = '';
      state.user = null;
      state.credentials = null;
    },
  },
});

export const { setAuthToken, setCredentials, setUser, logOut } =
  authSlice.actions;

export default authSlice.reducer;
