import React from 'react';

import { Icons } from '../../../../helpers/icons';

interface EmptyCardProps {
  isGeneration: boolean;
}

export const EmptyCard = (props: EmptyCardProps) => {
  const { isGeneration } = props;

  const textStyle = 'md:text-xl text-base text-center tracking-[1px] max-w-xs';
  const boldStyle = 'font-semibold';

  return (
    <div className="w-full items-center text-center flex flex-col">
      <Icons.EmptyContainer className="mb-6" />
      {isGeneration ? (
        <p className={textStyle}>
          <span className={boldStyle}>Talk with Chatbot</span> or{' '}
          <span className={boldStyle}>Upload Image</span> to Generate the Custom
          Keywords and Titles
        </p>
      ) : (
        <p className={textStyle}>
          Please Click{' '}
          <span className={boldStyle}>Generate Tag/AltText/ Description</span>{' '}
          to see the listing.
        </p>
      )}
    </div>
  );
};
