import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface HistoryEnableState {
  historyEnabled: boolean;
}

const initialState: HistoryEnableState = {
  historyEnabled: false,
};

const historyEnableSlice = createSlice({
  name: 'historyEnable',
  initialState,
  reducers: {
    setHistoryEnabled: (state, action: PayloadAction<boolean>) => {
      state.historyEnabled = action.payload;
    },
  },
});

export const { setHistoryEnabled } = historyEnableSlice.actions;
export default historyEnableSlice.reducer;
