export const headerItems = [
  {
    label: 'Account Detail',
    href: '/account-details',
  },
  {
    label: 'Sign Out',
    href: '/login',
  },
];

export const ALERT_MESSAGES = {
  CONSUME_TRIAL_LISTINGS: 'You must consume your trial listings before purchasing a new plan.',
  CUSTOM_KEYWORDS_NOT_GENERATED: 'Custom Keywords not generated',
  ALT_TEXT_NOT_GENERATED: 'Alt Text not generated',
  DESCRIPTION_NOT_GENERATED: 'Description not generated',
  TAGS_NOT_GENERATED: 'Tags not generated',
  TITLES_NOT_GENERATED: 'Titles not generated',
  TITLE_GENERATING: 'Generating titles',
  TITLE_GENERATED_SUCCESS: 'Titles generated successfully',
  TITLE_GENERATION_FAILED: 'Failed to generate titles',
  GENERATION_GENERATION_FAILED: 'Failed to generate generations',
  CUSTOM_KEYWORDS_GENERATED_SUCCESS: 'Custom Keywords generated successfully',
  CUSTOM_KEYWORDS_SAVE_SUCCESS: 'Keywords updated successfully',
  CUSTOM_KEYWORDS_SAVE_FAILED: 'Keywords update unsuccessfully',
  ALT_TEXT_GENERATED_SUCCESS: 'Alt Text generated successfully',
  DESCRIPTION_GENERATED_SUCCESS: 'Description generated successfully',
  DESCRIPTION_SAVE_SUCCESS: 'Description updated successfully',
  DESCRIPTION_SAVE_FAILED: 'Description update failed',
  TAGS_GENERATED_SUCCESS: 'Tags generated successfully',
  ALT_CARD_DATA_GENERATION_FAILED: 'Failed to generate alt card data',
  TAGS_SAVE_SUCCESS: 'Saved your edited tags!',
  TAGS_SAVE_FAILED: 'Tags save failed!',
  TITLE_SAVE_SUCCESS: 'Saved your edited title!',
  ALTTEXT_SAVE_SUCCESS: 'Saved your edited Alt Text!',
  TITLE_SAVE_FAILED: 'Title edit unsuccessful',
  ALTTEXT_SAVE_FAILED: 'Alt Text edit unsuccessful',
  TITLE_PICK_SUCCESS: (title: string) => `${title} has been selected!`,
  ALTTEXT_PICK_SUCCESS: (altText: string) => `${altText} has been selected!`,
  TITLE_PICK_FAILED: 'Failed to save your pick',
  ALTTEXT_PICK_FAILED: 'Failed to save your pick',
  TITLE_ALREADY_SELECTED: (title: string) =>
    `${title} has already been selected!`,
  SESSION_LOADING: 'Loading session data.',
  CHANGE_PASSWORD_SUCCESS:
    'Password has been updated successfully. Please login again using new password.',
  INVALID_PASSWORD: 'Invalid OTP or password',
  OTP_VERIFICATION_FAILED: 'OTP verification failed',
  UPDATE_PASSWORD: 'Password has been updated successfully.',
  SESSION_LOAD_FAILED: 'Failed to load session',
};
