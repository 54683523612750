/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from 'react-redux';
import React from 'react';
import { useCallback, useEffect, useState } from 'react';

import Card from '../../shared/ui/Card';
import { EmptyCard } from '../../shared/ui/EmptyCard';
import { InputCard } from '../../shared/ui/InputCard';
import useGenericMutation from '../../../CustomHooks/useMutation';
import {
  AltTextResponse,
  GeneratedData,
  StatusResponse,
} from '../../../common/types';
import useAlert from '../../../CustomHooks/useAlert';
import Alert from '../../shared/ui/Alert';
import { ALERT_MESSAGES } from '../../../constants';
import { RootState } from '../../../redux/store';

interface AltCardProps {
  isAltCardGenerated: boolean;
  generatedAltText?: AltTextResponse | null;
  generatedAltDescription: string;
  generatedAltTags?: string[];
  generatedData: GeneratedData;
  generatedAltCustomKeywords: string[] | [];
}

interface AltCardChildProps {
  generatedAltText?: AltTextResponse;
  generatedDescription?: string;
  generatedTags?: string[];
  generatedCustomKeywords?: string[];
  isAltGenerated: boolean;
}

const AltTextCard: React.FC<any> = ({ generatedAltText }) => {
  const [altTexts, setAltTexts] = useState<string[]>([]);

  useEffect(() => {
    if (generatedAltText) {
      setAltTexts(generatedAltText.alt_text || []);
    }
  }, [generatedAltText]);

  return (
    <div className="overflow-x-auto">
      <div className="flex pb-4">
        {altTexts.map((altText: string, index: number) => (
          <div
            key={index}
            className="flex-1 min-w-[280px] px-2 first:pl-0 last:pr-0"
          >
            <Card
              isAlt
              altHeadingClassName="text-base"
              heading={`Alt Text ${index + 1}`}
              className="bg-[#f5f5f5] h-full"
            >
              {altText}
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
};

const AltCardChild = (props: AltCardChildProps) => {
  const listingId = useSelector((state: RootState) => state.listing.listingId);
  const {
    generatedAltText,
    generatedDescription,
    generatedTags,
    generatedCustomKeywords,
    isAltGenerated,
  } = props;

  const { alert, setAlert, closeAlert } = useAlert();

  const [isBetter, setBetter] = useState<boolean>(false);

  const { mutateAsync: updateAltGeneration } =
    useGenericMutation<StatusResponse>(`/listing/${listingId}`, 'PUT');

  useEffect(() => {
    if (generatedAltText?.selected === 0) {
      setBetter(true);
    }
  }, [generatedAltText]);

  const toggleIsBetter = useCallback(() => setBetter(prev => !prev), []);

  const handleTagSave = async (value: string) => {
    const tagArray = value.split(',');
    try {
      await updateAltGeneration({
        tags: tagArray,
      });

      setAlert({
        isSuccess: true,
        message: ALERT_MESSAGES.TAGS_SAVE_SUCCESS,
      });
    } catch {
      setAlert({
        isSuccess: false,
        message: ALERT_MESSAGES.TAGS_SAVE_FAILED,
      });
    }
  };

  const handleKeywordsSave = async (value: string) => {
    try {
      const keywordsArray = value.split(',').map(keyword => keyword.trim());
      await updateAltGeneration({
        keywords: keywordsArray,
      });
      setAlert({
        isSuccess: true,
        message: ALERT_MESSAGES.CUSTOM_KEYWORDS_SAVE_SUCCESS,
      });
    } catch (error) {
      setAlert({
        isSuccess: false,
        message: ALERT_MESSAGES.CUSTOM_KEYWORDS_SAVE_FAILED,
      });
    }
  };

  return (
    <>
      {alert && (
        <Alert
          isSuccess={alert.isSuccess}
          message={alert.message}
          onClose={closeAlert}
        />
      )}
      {isAltGenerated ? (
        <div className="space-y-6">
          {generatedAltText && (
            <AltTextCard
              generatedAltText={generatedAltText}
              isBetter={isBetter}
              toggleIsBetter={toggleIsBetter}
              listingId={listingId}
            />
          )}
          {generatedDescription && (
            <Card
              isAlt
              heading="Description"
              className="bg-[#f5f5f5]"
              isHtml={true}
            >
              <span
                className="prose prose-sm text-gray-700 leading-6 max-w-none [&>p]:my-3 [&>h2]:my-3 [&>h3]:my-3 [&>h5]:my-3"
                dangerouslySetInnerHTML={{
                  __html: generatedDescription,
                }}
              />
            </Card>
          )}
          {generatedTags && (
            <InputCard
              title={'Tags'}
              initialValue={generatedTags.join(', ')}
              showSaveButton
              onSave={handleTagSave}
              maxWords={13}
              validationType="maxwords"
            />
          )}

          {generatedCustomKeywords && (
            <InputCard
              title={'Keywords'}
              initialValue={generatedCustomKeywords?.join(', ')}
              showSaveButton
              onSave={handleKeywordsSave}
              validationType="maxwords"
              maxWords={20}
              isKeywords={true}
            />
          )}
        </div>
      ) : (
        <EmptyCard isGeneration={false} />
      )}
    </>
  );
};

const AltCard = (props: AltCardProps) => {
  const {
    isAltCardGenerated,
    generatedAltText,
    generatedAltDescription,
    generatedAltTags,
    generatedData,
    generatedAltCustomKeywords,
  } = props;

  return (
    <Card
      heading={'AltText/Description/Tags'}
      children={
        <AltCardChild
          generatedAltText={
            generatedAltText || generatedData.altText || undefined
          }
          generatedDescription={
            generatedAltDescription || generatedData.description?.description
          }
          generatedTags={generatedAltTags || generatedData.tags?.tags}
          generatedCustomKeywords={generatedAltCustomKeywords}
          isAltGenerated={isAltCardGenerated}
        />
      }
    />
  );
};

export default AltCard;
