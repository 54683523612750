import React, { useState, useEffect, useCallback } from 'react';
import { useSignUp } from '@clerk/clerk-react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce';

import { InputField } from '../../components/shared/ui/InputField';
import { Icons } from '../../helpers/icons';
import { Button } from '../../components/shared/ui/Button';
import { signUpResolver } from '../../validators/AuthValidators';
import PasswordValidation from '../../components/shared/ui/PassswordValidation';

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
}

const SignUp: React.FC = () => {
  const { signUp } = useSignUp();
  const navigate = useNavigate();
  const [isLoading, setLoaded] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [retryCount, setRetryCount] = useState<number>(0);
  const MAX_RETRIES = 2;

  const [showFirstNameError, setShowFirstNameError] = useState<boolean>(false);
  const [showLastNameError, setShowLastNameError] = useState<boolean>(false);
  const [showEmailError, setShowEmailError] = useState<boolean>(false);
  const [showPasswordError, setShowPasswordError] = useState<boolean>(false);
  const [showConfirmPasswordError, setShowConfirmPasswordError] =
    useState<boolean>(false);

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    setValue,
    trigger,
    watch,
  } = useForm<FormValues>({ resolver: signUpResolver, mode: 'onChange' });

  const watchedFields = watch([
    'firstName',
    'lastName',
    'email',
    'password',
    'confirmPassword',
  ]);

  const debouncedTrigger = useCallback(
    debounce(() => {
      trigger();
    }, 100),
    [trigger]
  );

  useEffect(() => {
    const allFieldsFilled = Object.values(watchedFields).every(
      value => value !== '' && value !== undefined
    );

    if (allFieldsFilled && !isValid) {
      debouncedTrigger();
    }
  }, [watchedFields, debouncedTrigger, isValid]);

  const debouncedValidation = (field: keyof FormValues) => {
    setTimeout(() => {
      trigger(field).then(() => {
        switch (field) {
          case 'firstName':
            setShowFirstNameError(true);
            break;
          case 'lastName':
            setShowLastNameError(true);
            break;
          case 'email':
            setShowEmailError(true);
            break;
          case 'confirmPassword':
            setShowConfirmPasswordError(true);
            break;
        }
      });
    }, 2000);
  };

  const handleFieldChange = (field: keyof FormValues, value: string) => {
    setValue(field, value, { shouldValidate: false });
    switch (field) {
      case 'firstName':
        setShowFirstNameError(false);
        break;
      case 'lastName':
        setShowLastNameError(false);
        break;
      case 'email':
        setShowEmailError(false);
        break;
      case 'password':
        setShowPasswordError(false);
        setPassword(value);
        break;
      case 'confirmPassword':
        setShowConfirmPasswordError(false);
        break;
    }
    debouncedValidation(field);
  };

  const onSubmit = async (data: FormValues) => {
    setLoaded(true);
    setErrorMessage('');

    try {
      const signUpRes = await signUp?.create({
        firstName: data.firstName,
        lastName: data.lastName,
        emailAddress: data.email,
        password: data.password,
      });

      if (!signUpRes) {
        throw new Error('Sign up response is undefined');
      }

      await signUpRes.prepareEmailAddressVerification({
        strategy: 'email_link',
        redirectUrl: `${window.location.origin}/verification`,
      });
      setLoaded(false);
      navigate('/email-sent', { state: { email: data.email } });
    } catch (error: any) {
      console.error('Sign up error:', error);

      if (
        error?.errors?.[0]?.code === 'authentication_invalid' &&
        retryCount < MAX_RETRIES
      ) {
        setRetryCount(prevCount => prevCount + 1);
        setTimeout(() => {
          handleSubmit(onSubmit)();
        }, 1000);
      } else {
        setLoaded(false);
        if (error?.errors?.length > 0) {
          const errorMessage = error.errors[0].message;
          console.log('Error message:', errorMessage);
          setErrorMessage(errorMessage);
        } else {
          console.error('An unknown error occurred:', error);
          setErrorMessage('An unexpected error occurred. Please try again.');
        }
      }
    }
  };

  return (
    <div className="content-container bg-[#F9F9F9]">
      <div className="bg-white px-4 py-6 my-4 md:p-8 rounded-lg shadow-md max-w-xl border border-[#C9CBCD]">
        <h2 className="text-xl md:text-3xl font-bold md:mt-10 mb-1 tracking-wider">
          Welcome
        </h2>
        <h1 className="text-[#3F3F3F] text-sm md:text-base mb-6 mt-4">
          Please enter your information below to start simplifying your Etsy SEO
        </h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-3">
            <div className="columns-2 flex items-center space-x-4">
              <InputField
                errorMsg={
                  showFirstNameError ? errors.firstName?.message : undefined
                }
                register={register}
                name="firstName"
                placeholder="First Name"
                Icon={Icons.User}
                autoCompleteValue="given-name"
                onInput={e =>
                  handleFieldChange('firstName', e.currentTarget.value)
                }
              />
              <InputField
                errorMsg={
                  showLastNameError ? errors.lastName?.message : undefined
                }
                register={register}
                name="lastName"
                placeholder="Last Name"
                Icon={Icons.User}
                autoCompleteValue="family-name"
                onInput={e =>
                  handleFieldChange('lastName', e.currentTarget.value)
                }
              />
            </div>
            <InputField
              errorMsg={showEmailError ? errors.email?.message : undefined}
              register={register}
              name="email"
              placeholder="Ex.email@domain.com"
              Icon={Icons.Mail}
              onInput={e => handleFieldChange('email', e.currentTarget.value)}
            />
            <InputField
              errorMsg={
                showPasswordError ? errors.password?.message : undefined
              }
              register={register}
              name="password"
              type="password"
              placeholder="Password"
              onInput={e =>
                handleFieldChange('password', e.currentTarget.value)
              }
              Icon={Icons.Password}
              autoCompleteValue="new-password"
            />
            <InputField
              errorMsg={
                showConfirmPasswordError
                  ? errors.confirmPassword?.message
                  : undefined
              }
              register={register}
              name="confirmPassword"
              type="password"
              placeholder="Confirm Password"
              onInput={e =>
                handleFieldChange('confirmPassword', e.currentTarget.value)
              }
              Icon={Icons.Password}
              autoCompleteValue="new-password"
            />
          </div>
          {errorMessage && (
            <p className="mt-3 text-sm md:text-base text-red-10">
              {errorMessage}
            </p>
          )}
          <PasswordValidation password={password} />
          <div className="mt-8">
            <Button
              btnType="signUp"
              type="submit"
              disabled={!isValid}
              fullWidth
              variant="primary"
              label="Sign up"
              size="xl"
              loading={isLoading}
            />
          </div>
          <div className="mt-4 text-xs md:text-sm text-center">
            <span className="text-gray-600">Already have an account? </span>
            <a
              href="/login"
              className="text-etsyPrimary font-semibold underline"
            >
              Sign in
            </a>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignUp;
