import React, { useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useClerk, useUser } from '@clerk/clerk-react';

import { Images } from '../../../../helpers/images';
import { capitalize } from '../../../../helpers/utils';
import { Icons } from '../../../../helpers/icons';
import { useDispatch, useSelector } from 'react-redux';
import { setUser } from '../../../../redux/feature/AuthSlice';
import { setSessionID } from '../../../../redux/feature/SessionSlice';
import { RootState } from '../../../../redux/store';

interface SideBarProps {
  isMobile: boolean;
  onClose?: () => void;
}

const Sidebar = (props: SideBarProps) => {
  const { isMobile, onClose = () => {} } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useUser();
  const { signOut } = useClerk();
  const dispatch = useDispatch();

  const historyEnabled = useSelector(
    (state: RootState) => state.historyEnable.historyEnabled
  );

  const handleSignOut = async () => {
    dispatch(setUser(null));
    dispatch(setSessionID(null));
    await signOut();
  };

  const openAccountDetails = () => {
    onClose();
    navigate('/account-details');
  };

  useEffect(() => {
    document.body.style.overflow = isMobile ? (isMobile ? 'hidden' : '') : '';
    return () => {
      document.body.style.overflow = '';
    };
  }, [isMobile]);

  const isActive = (path: string) => location.pathname === path;

  const menuItems = [
    { path: '/home', label: 'Add New Listings' },
    { path: '/session-history', label: 'History' },
    { path: 'https://etseo.com/faq/', label: 'FAQ' },
    { path: 'https://etseo.com/contact', label: 'Support' },
  ];

  return (
    <div
      className={`fixed top-0 md:pb-16  pb-11 z-12 left-0 h-full md:z-0 z-[11] bg-white drop-shadow-md overflow-hidden pt-5 text-black ${
        isMobile ? 'w-full' : 'w-80 md:block hidden'
      }`}
    >
      <div className="flex flex-col h-full">
        <div className="flex flex-row md:justify-center justify-between items-center">
          <Link to="/home" onClick={onClose}>
            <img
              src={Images.headerLogo}
              alt="Logo"
              className="h-24 w-48 cursor-pointer"
            />
          </Link>
          {isMobile && (
            <div className="md:hidden cursor-pointer" onClick={onClose}>
              <Icons.Cross />
            </div>
          )}
        </div>
        <div className="flex flex-col mt-12 text-lg">
          {menuItems.map(item => (
            <div
              key={item.path}
              onClick={() => {
                if (item.path.startsWith('http')) {
                  window.open(item.path, '_blank');
                } else {
                  navigate(item.path);
                }
              }}
              className={`cursor-pointer font-bold py-2 px-4 pl-9 ${
                isActive(item?.path)
                  ? 'bg-[#FAE8DE] text-etsyPrimary border-r-4 border-etsyPrimary'
                  : 'hover:bg-[#FAE8DE] hover:text-etsyPrimary'
              } ${item.path === '/session-history' && historyEnabled ? 'hiss' : ''}`}
            >
              {item.label}
            </div>
          ))}
        </div>
        <div className="flex flex-col justify-end mt-auto bg-[#F5F5F5] mx-4 p-3 rounded-lg">
          <div className="flex justify-between items-center cursor-pointer">
            <div
              className="flex items-center md:space-x-2 space-x-1"
              onClick={openAccountDetails}
            >
              <img
                src={user?.imageUrl}
                alt="User"
                className="md:h-11 md:mr-2 mr-1 rounded-full md:w-11 w-8 h-8"
              />
              <span className="text-black font-bold text:sm md:text-lg flex-1 overflow-hidden overflow-ellipsis">
                {capitalize(user?.fullName!)}
              </span>
            </div>
            <div
              className="flex border-l items-center border-l-gray h-full pl-4 cursor-pointer"
              onClick={handleSignOut}
            >
              <Icons.Signout className="self-center" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
