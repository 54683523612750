import React, { useCallback, useEffect, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Icons } from '../../../../helpers/icons';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import useGenericMutation from '../../../../CustomHooks/useMutation';
import { CheckoutSessionResponse } from '../../../../common/types/stripeSessions';
import { axiosStripeInstance } from '../../../../api/apiInstances';
import { useUser } from '@clerk/clerk-react';
import TagManager from 'react-gtm-module';
import { useAppSelector } from '../../../../helpers/hooks';

const PaymentCancel = () => {
  const navigate = useNavigate();
  const { user } = useUser();
  const selectedPlan = useSelector(
    (state: RootState) => state.stripe.selectedPlan
  );

  const { mutateAsync: createCheckoutSession } =
    useGenericMutation<CheckoutSessionResponse>(
      '/subscription/create-checkout-session',
      'POST',
      axiosStripeInstance
    );

  const stripeSessionId = useAppSelector(state => state.stripe.stripeSessionId);

  useLayoutEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'checkout_end',
        checkout_session_id: stripeSessionId,
        success: false,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.history.pushState(null, '', window.location.href);

    window.addEventListener('popstate', () => {
      navigate('/home');
    });

    return () => {
      window.removeEventListener('popstate', () => {
        navigate('/home');
      });
    };
  }, [navigate]);

  const successPath = `/stripe-success?params=${btoa('fromStripe=true')}`;
  const cancelPath = `/stripe-cancel?params=${btoa('fromStripe=true')}`;

  const handleTryAgain = useCallback(async () => {
    if (selectedPlan) {
      try {
        const response = await createCheckoutSession({
          planId: selectedPlan,
          userId: user?.id,
          successUrl: `${window.location.origin}${successPath}`,
          cancelUrl: `${window.location.origin}${cancelPath}`,
        });
        window.location.href = response.paymentUrl;
      } catch (error) {
        console.error('Error creating checkout session:', error);
      }
    }
  }, [selectedPlan, createCheckoutSession, user?.id, successPath, cancelPath]);

  return (
    <div className="flex flex-col items-center justify-center mx-4">
      <Icons.Notification className="w-16 h-16 mb-6" />
      <h1 className="text-lg md:text-xl font-semibold mb-1">
        Your subscription is unsuccessful
      </h1>
      <p className="text-sm md:text-base mb-7">
        There was an error occurred during the process, please try again.
      </p>
      <div className="flex md:flex-row flex-col md:space-x-5 md:space-y-0 space-y-5  w-full justify-center">
        <button
          onClick={() => navigate('/account-details')}
          className="bg-white text-black border px-8 py-2 rounded-lg min-w-[190px]"
        >
          Cancel
        </button>
        <button
          onClick={handleTryAgain}
          className="bg-[#B3001F] text-white px-8 py-2 rounded-lg min-w-[190px]"
        >
          Try again
        </button>
      </div>
    </div>
  );
};

export default PaymentCancel;
