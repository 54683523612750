import React from 'react';

const Footer = () => {
  return (
    <div className="bg-gradient-to-r from-etsyPrimary to-[#1eb995] text-white font-bold text-sm py-4 w-full text-center z-10">
      <p>
        ©2024 Seller Success Academy, LLC. All rights reserved.
      </p>
      <p className='text-xs mt-1' >
        The term 'Etsy' is a trademark of Etsy, Inc. This application uses the Etsy API but is not endorsed or certified by Etsy, Inc.
      </p>
    </div>
  );
};

export default Footer;
