import { ReactComponent as ChevronWhite } from '../assets/icons/ChevronWhite.svg';
import { ReactComponent as ChevronBlack } from '../assets/icons/ChevronBlack.svg';
import { ReactComponent as DragNDrop } from '../assets/icons/DragNDrop.svg';
import { ReactComponent as EmptyContainer } from '../assets/icons/EmptyContainer.svg';
import { ReactComponent as Copy } from '../assets/icons/Copy.svg';
import { ReactComponent as Check } from '../assets/icons/Check.svg';
import { ReactComponent as Cross } from '../assets/icons/Cross.svg';
import { ReactComponent as Notification } from '../assets/icons/Notification.svg';
import { ReactComponent as Mail } from '../assets/icons/mail.svg';
import { ReactComponent as Password } from '../assets/icons/password.svg';
import { ReactComponent as Error } from '../assets/icons/error.svg';
import { ReactComponent as GreenCheck } from '../assets/icons/greenCheck.svg';
import { ReactComponent as RedCheck } from '../assets/icons/redCheck.svg';
import { ReactComponent as GreyCheck } from '../assets/icons/greyCheck.svg';
import eyeOff from '../assets/icons/eye-off.svg';
import eyeOn from '../assets/icons/eye-on.svg';
import { ReactComponent as ChatBot } from '../assets/icons/ChatBot.svg';
import { ReactComponent as User } from '../assets/icons/user.svg';
import { ReactComponent as Trash } from '../assets/icons/Trash.svg';
import { ReactComponent as Profile } from '../assets/icons/Profile.svg';
import { ReactComponent as Edit } from '../assets/icons/edit.svg';
import { ReactComponent as Chevron } from '../assets/icons/chevron.svg';
import { ReactComponent as SaveDisabled } from '../assets/icons/SaveDisabled.svg';
import { ReactComponent as Save } from '../assets/icons/Save.svg';
import { ReactComponent as EmailCheck } from '../assets/icons/EmailCheck.svg';
import { ReactComponent as Dash } from '../assets/icons/Dash.svg';
import { ReactComponent as Plus } from '../assets/icons/Plus.svg';
import { ReactComponent as Back } from '../assets/icons/Back.svg';
import { ReactComponent as Clock } from '../assets/icons/Clock.svg';
import { ReactComponent as Signout } from '../assets/icons/Signout.svg';
import { ReactComponent as Hamburger } from '../assets/icons/Hamburger.svg';
import { ReactComponent as Checkmark } from '../assets/icons/Checkmark.svg';
import { ReactComponent as Pencil } from '../assets/icons/Pencil.svg';
import { ReactComponent as HistoryPlaceholder } from '../assets/icons/HistoryPlaceholder.svg';
import { ReactComponent as GreenInfo } from '../assets/icons/GreenInfo.svg';
import { ReactComponent as RedInfo } from '../assets/icons/RedInfo.svg';

const Icons = {
  ChevronWhite,
  ChevronBlack,
  DragNDrop,
  EmptyContainer,
  Copy,
  Check,
  Cross,
  Notification,
  Mail,
  eyeOff,
  eyeOn,
  User,
  Password,
  Error,
  ChatBot,
  Trash,
  Profile,
  GreenCheck,
  RedCheck,
  GreyCheck,
  Edit,
  Chevron,
  SaveDisabled,
  Save,
  EmailCheck,
  Dash,
  Plus,
  Back,
  Clock,
  Signout,
  Hamburger,
  Checkmark,
  Pencil,
  HistoryPlaceholder,
  GreenInfo,
  RedInfo,
};

export { Icons };
