import React from 'react';
import UserProfileForm from '../../components/shared/ui/UserProfileForm';

const UserProfile = ({ isProfilePage }: { isProfilePage: boolean }) => {
  return (
    <div
      className={`${
        isProfilePage
          ? 'content-container flex justify-center items-center bg-white rounded-xl md:mx-20 mx-4 my-11 md:px-10 px-4'
          : ''
      }`}
    >
      <UserProfileForm isProfilePage={isProfilePage} />
    </div>
  );
};

export default UserProfile;
