/* eslint-disable eqeqeq */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useUser } from '@clerk/clerk-react';

import Card from '../../components/shared/ui/Card';
import BillingPlanModal from '../../components/AccountDetails/BillingPlansModal';
import ChangePasswordModal from '../../components/AccountDetails/ChangePasswordModal';
import { Icons } from '../../helpers/icons';
import useGenericGetQuery from '../../CustomHooks/useQuery';
import {
  axiosStripeInstance,
  useAxiosStripeInterceptors,
} from '../../api/apiInstances';
import { MyStripeSubscription } from '../../common/types/stripeSessions';
import Loader from '../../components/shared/layout/Loader';
import UserProfileForm from '../../components/shared/ui/UserProfileForm';
import TagManager from 'react-gtm-module';

const AccountDetails = () => {
  const { user } = useUser();
  const userId = user?.id;
  useAxiosStripeInterceptors();

  const [plansModalOpened, setPlansModalOpened] = useState(false);
  const [passwordModalOpened, setPasswordModalOpened] = useState(false);
  const [isQueryEnabled, setIsQueryEnabled] = useState(true);
  const [isBottom, setIsBottom] = useState(false);

  const { data: availablePlans, isFetching } =
    useGenericGetQuery<MyStripeSubscription>(
      '/subscription/all-subscriptions',
      axiosStripeInstance,
      { userId },
      undefined,
      { enabled: isQueryEnabled }
    );

  const currentPlan = useMemo(() => {
    return (
      availablePlans?.basicPlans.find(
        plan =>
          plan.currentPlan === true ||
          plan.status == 'active' ||
          plan.status == 'expired'
      ) || null
    );
  }, [availablePlans]);

  useEffect(() => {
    setIsQueryEnabled(false);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;

      if (windowHeight + scrollTop >= documentHeight - 400) {
        setIsBottom(true);
      } else {
        setIsBottom(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const isExpired = currentPlan?.status == 'expired';

  const renderHeading = useCallback(() => {
    return (
      <div className="flex flex-col md:flex-row md:items-center">
        <div className="md:flex md:items-center">
          <div className="md:text-xl text-base md:mb-0 mb-2">
            {currentPlan ? currentPlan.title : 'No plan subscribed'}
          </div>
        </div>
      </div>
    );
  }, [currentPlan]);

  const renderListings = useCallback(() => {
    return (
      <>
        <p>- Total Listings: {currentPlan?.totalListings}</p>
        <p>- Remaining Listings: {currentPlan?.remainingListings}</p>
      </>
    );
  }, [currentPlan]);

  const renderSubHeading = useCallback(() => {
    return currentPlan ? (
      <div className="flex flex-col justify-between ">
        <span>{currentPlan.description}</span>
        <div className="mt-4 flex-col md:hidden ">{renderListings()}</div>
        <div className="mt-4 flex-col md:inline-block hidden text-sm space-y-2">
          {renderListings()}
        </div>
      </div>
    ) : (
      'You have not subscribed to any plan, please select a plan...'
    );
  }, [currentPlan, renderListings]);

  return (
    <div className="min-w-full md:pb-[40px] pb-8">
      <Card
        heading="Account Detail"
        subHeading="Customize your account settings below"
      >
        <UserProfileForm />
        <div className="md:flex justify-between md:items-center pb-8 mb:pb-11 mb-8 border-b">
          <h2 className="font-bold text-lg md:font-extrabold md:text-2xl mb-4 md:mb-0">
            Password
          </h2>
          <button
            type="button"
            className="border-[1px] px-6 py-3 rounded-lg border-etsyPrimary font-bold text-lg text-etsyPrimary w-full md:w-auto"
            onClick={() => setPasswordModalOpened(true)}
          >
            Change Password
          </button>
        </div>
        <div>
          <h2 className="font-bold text-xl md:font-extrabold md:text-2xl mb-6">
            Billing Management
          </h2>
          {isFetching ? (
            <Loader />
          ) : (
            <Card
              className={'!p-6 !pb-8'}
              headingClassName={'md:text-xl !text-base !font-semibold'}
              subHeadingClassName={
                '!font-normal !md:text-base text-sm !text-[#828282] !leading-[normal]'
              }
              heading={renderHeading()}
              subHeading={renderSubHeading()}
            >
              <div
                className={`flex flex-row justify-between w-100 cursor-pointer ${isBottom ? 'bbb' : ''}`}
                onClick={() => {
                  TagManager.dataLayer({
                    dataLayer: {
                      event: 'checkout_pending',
                    },
                  });
                  setPlansModalOpened(true);
                }}
              >
                <span
                  className={`md:text-xl text-base !font-semibold ${isBottom ? 'bbb' : ''}`}
                >
                  {currentPlan && !isExpired ? 'Change Plan' : 'Select a Plan'}
                </span>
                <Icons.Chevron />
              </div>
            </Card>
          )}
        </div>
        <BillingPlanModal
          isOpen={plansModalOpened}
          closeModal={() => setPlansModalOpened(false)}
        />
        <ChangePasswordModal
          isOpen={passwordModalOpened}
          closeModal={() => setPasswordModalOpened(false)}
          onSave={() => null}
        />
      </Card>
    </div>
  );
};

export default AccountDetails;
