import { Provider } from 'react-redux';
import { QueryClientProvider, QueryClient } from 'react-query';
import { PersistGate } from 'redux-persist/integration/react';

import { persistor, store } from './redux/store';
import './App.css';
import Routing from './Routing';
import { useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';
declare global {
  interface Window {
    pendo: any;
  }
}
function App() {
  const queryClient = new QueryClient();
  const { user } = useUser();

  useEffect(() => {
    if (window.pendo) {
      window.pendo.initialize({
        visitor: {
          id: user?.id,
          email: user?.emailAddresses[0].emailAddress,
        },
        account: {
          id: 'EtSEO',
        },
      });
    }
  }, []);
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Routing />
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
