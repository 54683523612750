import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CheckOutState {
  sessionID: string | null;
  isCheckingOut: boolean;
}

const initialState: CheckOutState = {
  sessionID: null,
  isCheckingOut: false,
};

const checkOutSlice = createSlice({
  name: 'checkOut',
  initialState,
  reducers: {
    setSessionID: (state, action: PayloadAction<string | null>) => {
      state.sessionID = action.payload;
    },
    setIsCheckingOut: (state, action: PayloadAction<boolean>) => {
      state.isCheckingOut = action.payload;
    },
  },
});

export const { setSessionID, setIsCheckingOut } = checkOutSlice.actions;
export default checkOutSlice.reducer;
