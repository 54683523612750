/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import Modal from '../../shared/ui/Modal';
import { Icons } from '../../../helpers/icons';
import { axiosStripeInstance } from '../../../api/apiInstances';
import useGenericGetQuery from '../../../CustomHooks/useQuery';
import useGenericMutation from '../../../CustomHooks/useMutation';
import {
  BasicPlan,
  CheckoutSessionResponse,
  MyStripeSubscription,
} from '../../../common/types/stripeSessions';
import Loader from '../../shared/layout/Loader';
import { useClerk } from '@clerk/clerk-react';
import { Button } from '../../shared/ui/Button';
import { useAppDispatch } from '../../../helpers/hooks';
import {
  setStripeData,
  setStripeSessionId,
} from '../../../redux/feature/StripeSlice';
import { InputField } from '../../shared/ui/InputField';
import useAlert from '../../../CustomHooks/useAlert';
import Alert from '../../shared/ui/Alert';
import { ALERT_MESSAGES } from '../../../constants';
import TagManager from 'react-gtm-module';

interface BillingPlanModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

const buttonLabels = [
  'Continue with Small',
  'Continue with Medium',
  'Continue with Large',
  'Continue with X-Large',
  'Continue with Custom',
];

const BillingPlanModal = ({ isOpen, closeModal }: BillingPlanModalProps) => {
  const { user } = useClerk();
  const userId = user?.id;
  const [fetchEndpoint, setFetchEndpoint] = useState<string | null>(null);
  const [selectedPlan, setSelectedPlan] = useState<string | null>(null);
  const [hasCurrentPlan, setHasCurrentPlan] = useState<boolean>(false);
  const [currentBasicPlan, setCurrentBasicPlan] = useState<BasicPlan>();
  const dispatch = useAppDispatch();
  const [customListings, setCustomListings] = useState<string>('');
  const [customPrice, setCustomPrice] = useState<number>(0);
  const [customListingsError, setCustomListingsError] = useState<string>('');
  const { alert, setAlert, closeAlert } = useAlert();

  const { data: availablePlans, isLoading } =
    useGenericGetQuery<MyStripeSubscription>(
      fetchEndpoint ? fetchEndpoint : '',
      axiosStripeInstance,
      { userId }
    );
  useEffect(() => {
    setFetchEndpoint(isOpen ? '/subscription/all-subscriptions' : null);
  }, [isOpen]);

  const [filteredPlans, setFilteredPlans] = useState<MyStripeSubscription>();

  useEffect(() => {
    if (availablePlans) {
      const filteredPlans = {
        ...availablePlans,
        basicPlans: availablePlans.basicPlans.filter(
          plan => plan.tierType !== 'trial' && plan.tierType !== 'custom'
        ),
      };
      setFilteredPlans(filteredPlans);
      const filteredCustomPlan = availablePlans.basicPlans.filter(
        plan => plan.tierType === 'custom'
      );

      if (filteredCustomPlan.length > 0) {
        setHasCurrentPlan(true);
        setCurrentBasicPlan(filteredCustomPlan[0]);
        return;
      }

      const currentPlan = filteredPlans.basicPlans.find(
        plan =>
          plan.currentPlan ||
          plan.status == 'active' ||
          plan.status == 'expired'
      );
      setCurrentBasicPlan(currentPlan);
      if (currentPlan) {
        setSelectedPlan(currentPlan.id);
        setHasCurrentPlan(true);
      } else {
        setHasCurrentPlan(false);
      }
    }
  }, [availablePlans]);

  const [loadingStates, setLoadingStates] = useState<{
    [key: string]: boolean;
  }>({});

  const { mutateAsync: createCheckoutSession } =
    useGenericMutation<CheckoutSessionResponse>(
      '/subscription/create-checkout-session',
      'POST',
      axiosStripeInstance
    );

  const params = new URLSearchParams();
  params.set('fromStripe', 'true');
  params.set('trial', 'false'); // or 'false' as needed

  const encodedParams = btoa(params.toString());
  const successPath = `/stripe-success?params=${encodedParams}`;
  const cancelPath = `/stripe-cancel?params=${encodedParams}`;

  const { mutateAsync: updateUserProfile } = useGenericMutation<any>(
    '/users/profile',
    'PUT',
    axiosStripeInstance
  );

  const handleContinue = async (
    planId: string,
    price: number,
    listings: number
  ) => {
    if (planId === 'custom' && !customListings) {
      setCustomListingsError('Please enter the number of listings');
      setLoadingStates(prev => ({ ...prev, [planId]: false }));
      return;
    }
    setLoadingStates(prev => ({ ...prev, [planId]: true }));

    if (hasCurrentPlan) {
      try {
        await updateUserProfile({
          lastRemainingListings: currentBasicPlan?.remainingListings,
        });
      } catch (err) {
        console.log('Error cancelling subscription', err);
      }
    }

    try {
      const response = await createCheckoutSession({
        planId: planId,
        userId: userId,
        successUrl: `${window.location.origin}${successPath}`,
        cancelUrl: `${window.location.origin}${cancelPath}`,
        planType: planId === 'custom' ? 'custom' : 'basic',
        customListings: planId === 'custom' ? customListings : undefined,
      });
      dispatch(setStripeSessionId(response.id));
      TagManager.dataLayer({
        dataLayer: {
          event: 'checkout_start',
          checkout_session_id: response.id,
          plan_id: planId,
          listing_count: listings,
          price: price,
        },
      });
      if (response?.message?.includes('consume your trial listings')) {
        setAlert({
          isSuccess: false,
          message: ALERT_MESSAGES.CONSUME_TRIAL_LISTINGS,
        });
        return;
      }
      dispatch(
        setStripeData({ stripeUrl: response.paymentUrl, selectedPlan: planId })
      );
      window.open(response.paymentUrl, '_blank');
      setCustomListings('');
      closeModal();
    } catch (error) {
      console.error('Error creating checkout session:', error);
    } finally {
      setLoadingStates(prev => ({ ...prev, [planId]: false }));
    }
  };

  const handleCloseModal = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'checkout_cancelled',
      },
    });
    setSelectedPlan(null);
    closeModal();
  };

  const calculateCustomPrice = (listings: number) => {
    if (listings < 10) return 4.99;
    if (listings < 20) return 4.99;
    if (listings < 50) return 4.49;
    if (listings < 200) return 4.25;
    return 3.99;
  };

  const handleCustomListingsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setCustomListings(value);
    setCustomListingsError('');

    const numValue = parseInt(value);
    if (!isNaN(numValue)) {
      if (numValue < 10) {
        setCustomListingsError('Please enter a value of 10 or greater');
      } else if (numValue > 100000000) {
        setCustomListingsError('Please enter a value of 100,000,000 or less');
      } else {
        const price = calculateCustomPrice(numValue);
        setCustomPrice(price * numValue);
      }
    } else {
      setCustomPrice(0);
    }
  };

  const handleCustomListingsBlur = () => {
    if (customListings !== '' && parseInt(customListings) < 10) {
      setCustomListings('10');
      setCustomListingsError('');
      const price = calculateCustomPrice(10);
      setCustomPrice(price * 10);
    }
  };

  useEffect(() => {
    if (customListings === '') {
      setCustomPrice(0);
    } else {
      const listingsCount = Number(customListings);
      const price = calculateCustomPrice(listingsCount);
      setCustomPrice(price * listingsCount);
    }
  }, [customListings]);

  return (
    <Modal
      isOpen={isOpen}
      closeModal={handleCloseModal}
      className="w-[calc(100%-50px)] items-center p-6 pt-8 md:p-8 overflow-auto max-h-screen"
    >
      {alert && (
        <Alert
          isSuccess={alert.isSuccess}
          message={alert.message}
          onClose={closeAlert}
        />
      )}
      <div className="pb-6 mb-8 border-b">
        <div className="flex flex-row justify-between w-full">
          <div />
          <p className="font-semibold text-2xl mb-2">Choose a bundle</p>
          <div className="cursor-pointer" onClick={handleCloseModal}>
            <Icons.Cross />
          </div>
        </div>
        <p className="text-center font-normal text:sm md:text-base text-[#4E4E4E]">
          Pick a bundle to grow your Etsy business with etSEO
        </p>
      </div>
      <div className="mb-10 flex flex-wrap justify-center gap-6 overflow-auto text-[#828282]">
        {isLoading && <Loader />}
        {filteredPlans?.basicPlans.map((plan, index) => {
          return (
            <div
              key={plan.id}
              className={`shadow-[0px_0px_12px_0px_rgba(74,131,184,0.12)] bg-[#F3EDE9] rounded-lg flex flex-col
                p-6 pr-8 gap-1 cursor-pointer w-full md:w-[calc(50%-12px)] lg:w-[calc(33.33%-16px)] max-w-[400px] ${
                  plan.id === selectedPlan
                    ? 'border-etsyPrimary border'
                    : 'border-1'
                }`}
            >
              <div className=" w-full flex flex-col justify-center text-black">
                <p className={`md:text-3xl  text-lg font-semibold`}>
                  {plan.title}
                  {plan.currentPlan && (
                    <span className="md:text-base text-sm font-normal pl-3">
                      (Active Plan)
                    </span>
                  )}
                </p>
              </div>
              <div className="mt-3 text-sm md:text-base font-normal">
                {plan.description}
              </div>
              <p className="mt-12 text-etsyPrimary font-bold">
                {plan.featuresHeading}
              </p>
              <div className="text-xl mt-12 mb-5 md:text-3xl text-black">
                <p className="md:text-4xl text-xl font-bold ">
                  ${(plan.price / plan.basicPlanListings!).toFixed(2)}
                </p>
                <p className="text-sm md:text-base text-[#828282] ">
                  per listing
                </p>
              </div>

              <div className="h-[52px] flex items-center justify-center">
                <Button
                  label={buttonLabels[index] || 'Continue'}
                  onClick={() =>
                    handleContinue(plan.id, plan.price, plan.basicPlanListings!)
                  }
                  loading={loadingStates[plan.id]}
                  color="primary"
                  className="px-6 py-3 rounded-lg font-bold text-lg text-white w-full h-full"
                  loaderStroke="text-white"
                />
              </div>
              <div className="text-sm md:text-base text-[#716B6A] font-bold text-center mt-2">
                {plan.basicPlanListings} listings total (${plan.price})
              </div>
            </div>
          );
        })}

        <div className="shadow-[0px_0px_12px_0px_rgba(74,131,184,0.12)] bg-[#F3EDE9] rounded-lg flex flex-col p-6 pr-8 gap-1 cursor-pointer w-full md:w-[calc(50%-12px)] lg:w-[calc(33.33%-16px)] max-w-[400px]">
          <div className="w-full flex flex-col justify-center text-black">
            <p className="md:text-3xl text-black text-lg font-semibold">
              Custom Bundle
            </p>
          </div>
          <div className="mt-3 text-sm md:text-base font-normal">
            <p>10-20 listings: $4.99 per listing</p>
            <p>21-50 listings: $4.49 per listing</p>
            <p>51-200 listings: $4.25 per listing</p>
            <p>Over 201 listings: $3.99 per listing</p>
          </div>
          <div className="mt-2">
            <InputField
              name="customListings"
              placeholder="Enter amount of listings"
              value={customListings}
              onChange={handleCustomListingsChange}
              onBlur={handleCustomListingsBlur}
              inputContainerClass=" !border-etsyPrimary"
              type="number"
              min={10}
              max={100000000}
              errorMsg={customListingsError}
            />
          </div>
          <div className="text-xl mt-4 mb-5  md:text-3xl text-black">
            <p className="md:text-4xl text-xl font-bold">
              ${customPrice.toFixed(2)}
            </p>
            <p className="text-sm md:text-base text-[#828282] mt-6"> </p>
          </div>
          <div className="h-[52px] flex items-center justify-center">
            <Button
              label={buttonLabels[4]}
              onClick={() =>
                handleContinue('custom', customPrice, Number(customListings))
              }
              loading={loadingStates['custom']}
              color="primary"
              className="px-6 py-3 rounded-lg font-bold text-lg text-white w-full h-full "
              loaderStroke="text-white"
            />
          </div>
          {/* <div className="text-sm md:text-base text-[#716B6A] font-bold text-center mt-2">
            Over 200 listings
          </div> */}
        </div>
      </div>
      {!isLoading && (
        <div className="flex justify-end w-full gap-4">
          <button
            onClick={handleCloseModal}
            className="border-[1px] px-6 py-3 rounded-lg border-etsyPrimary font-semibold text-lg text-etsyPrimary min-w-[190px]"
          >
            Cancel
          </button>
        </div>
      )}
    </Modal>
  );
};

export default BillingPlanModal;
