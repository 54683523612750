import { ReactNode } from 'react';

import { OptionType } from '../components/shared/ui/DropDown';
import { store } from '../redux/store';
import { setSessionID } from '../redux/feature/SessionSlice';

export const transformHistoryToOptions = (data: any): OptionType[] => {
  return data.history.map((item: { id: any; name: any }) => ({
    value: item.id,
    label: item.name,
  }));
};

export const copyToClipboard = (
  children: ReactNode,
  setAlert: (alert: { isSuccess: boolean; message: string } | null) => void
) => {
  const contentToCopy = extractTextFromChildren(children);
  navigator.clipboard
    .writeText(contentToCopy)
    .then(() => {
      setAlert({ isSuccess: true, message: 'Content copied to clipboard!' });
      setTimeout(() => {
        setAlert(null);
      }, 3000);
    })
    .catch(() => {
      setAlert({ isSuccess: false, message: 'Failed to copy content.' });
    });
};

export const formatEmail = (email: string) => {
  return `${email.substring(0, 2)}...${email.split('@')[0].slice(-3)}@${email.split('@')[1]}`;
};

export const extractTextFromChildren = (children: ReactNode): string => {
  if (typeof children === 'string') {
    return children;
  }
  if (Array.isArray(children)) {
    return children.map(child => extractTextFromChildren(child)).join('');
  }
  if (typeof children === 'object' && children !== null) {
    if ('props' in children && 'children' in children.props) {
      return extractTextFromChildren(children.props.children);
    }
  }
  return '';
};

export const capitalize = (input: string) => {
  return input.replace(/\b\w/g, (char: string) => char.toUpperCase());
};

export const extractName = (input: string): string => {
  const regex = /(.+?)(?=\s(?:in|is|\d{2}:\d{2}:\d{2}-\d{2}\/\d{2}\/\d{4}))/;
  const match = input.match(regex);
  return match ? match[0].trim() : '';
};
export const countWords = (wordsString: string) => {
  const wordsArray = wordsString.split(',').map(tag => tag.trim());
  return wordsArray.length;
};

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return (
    date
      .toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true,
        timeZone: 'America/Los_Angeles',
      })
      .replace(',', ' -')
      .replace(/\//g, '-') + ' PST'
  );
};
