import React from 'react';
import Card from '../../components/shared/ui/Card';
import { Icons } from '../../helpers/icons';
import { copyToClipboard } from '../../helpers/utils';
import Alert from '../../components/shared/ui/Alert';
import useAlert from '../../CustomHooks/useAlert';

const email = 'help@etseo.io';

const Support = () => {
  const handleCopy = () => copyToClipboard(email, setAlert);
  const { alert, setAlert, closeAlert } = useAlert();
  return (
    <>
      {alert && (
        <Alert
          isSuccess={alert.isSuccess}
          message={alert.message}
          onClose={closeAlert}
        />
      )}
      <Card
        heading="Support"
        subHeading="We’re here to help and answer any question you might have. We look forward to hearing from you."
        className="w-full h-max"
      >
        <div className="w-full flex flex-col justify-center items-center">
          <h2 className="md:text-lg text:base font-semibold mb-3">
            Email us at
          </h2>
          <div className="mb-3 px-4 py-2 flex justify-between items-center border rounded-lg bg-white md:min-w-[380px] min-w-[320px]">
            <h3 className="font-semibold text-[#3F3F3F]  text-sm md:text-base mr-2">
              {email}
            </h3>
            <button
              className="text-etsyPrimary text-sm font-medium flex items-center "
              onClick={handleCopy}
            >
              <Icons.Copy className="mr-1" />
              Copy
            </button>
          </div>
        </div>
      </Card>
    </>
  );
};

export default Support;
