import React from 'react';

interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  label: string;
  disabled?: boolean;
  variant?: 'primary' | 'secondary' | 'tertiary';
  fullWidth?: boolean;
  size?: 'xs' | 'small' | 'medium' | 'large' | 'xl';
  color?: 'error' | 'primary' | 'success' | 'default';
  btnType?: string;
  showChevron?: boolean;
  className?: string;
  loading?: boolean;
  icon?: React.ReactNode;
  loaderStroke?: string;
  showIcon?: boolean;
}

export const Button = ({
  label,
  onClick,
  disabled = false,
  variant,
  showChevron = false,
  size,
  btnType,
  color,
  className = '',
  fullWidth = false,
  loading = false,
  icon,
  loaderStroke = 'text-white',
  showIcon = false,
  ...props
}: ButtonProps) => {
  const getVariant = () => {
    switch (variant) {
      case 'primary':
        return color === 'error'
          ? 'bg-danger text-white border-danger'
          : color === 'success'
            ? 'bg-green-200 text-white border-green-200'
            : color === 'primary'
              ? 'bg-etsyPrimary text-white border-etsyPrimary'
              : 'bg-white border text-black border-black';
      case 'secondary':
        return 'bg-white border text-black border-black';
      case 'tertiary':
        return 'bg-white text-black border-gray-300';
      default:
        return '';
    }
  };

  const getSize = () => {
    switch (size) {
      case 'xs':
        return 'px-2 py-1 text-xs';
      case 'small':
        return 'px-3 py-1.5 text-sm';
      case 'medium':
        return 'text-sm px-4 py-1 text-sm';
      case 'large':
        return 'px-3 py-3 text-base';
      case 'xl':
        return 'px-6 py-4 text-xl';
      default:
        return '';
    }
  };

  const renderLoader = () => {
    return <div className="loader self-center"></div>;
  };

  if (btnType === 'signUp') {
    return (
      <>
        {loading ? (
          <div className="flex justify-center">{renderLoader()}</div>
        ) : (
          <button
            onClick={onClick}
            disabled={disabled}
            className={`${
              fullWidth ? 'w-full' : ''
            } ${disabled ? 'bg-[#808080] text-[#B7B7B7]' : 'bg-etsyPrimary text-white'} py-3 text-sm font-semibold px-4 rounded-md ${className}`}
            {...props}
          >
            {label}
          </button>
        )}
      </>
    );
  } else {
    return (
      <>
        {loading ? (
          renderLoader()
        ) : (
          <button
            {...props}
            onClick={onClick}
            className={`font-semibold flex justify-center items-center rounded-md transition-all duration-200
            ${getVariant()} ${getSize()}
            ${disabled ? 'bg-[#808080] border border-[#808080] text-[#B7B7B7]' : 'bg-etsyPrimary'}
            ${fullWidth ? 'w-full' : ''}
            ${className}`}
            disabled={disabled || loading}
          >
            <>
              {label}
              {showIcon && icon && <span className="ml-2">{icon}</span>}
            </>
          </button>
        )}
      </>
    );
  }
};
