import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Modal from '../../shared/ui/Modal';
import { changePasswordResolver } from '../../../validators/AuthValidators';
import { Icons } from '../../../helpers/icons';
import { InputField } from '../../shared/ui/InputField';
import PasswordValidation from '../../shared/ui/PassswordValidation';
import { Button } from '../../shared/ui/Button';
import { useUser } from '@clerk/clerk-react';
import useAlert from '../../../CustomHooks/useAlert';
import { ALERT_MESSAGES } from '../../../constants';
import Alert from '../../shared/ui/Alert';

interface ChangePasswordFormValues {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

interface ChangePasswordModalProps {
  isOpen: boolean;
  closeModal: () => void;
  onSave: () => void;
}

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({
  isOpen,
  closeModal,
  onSave,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    setValue,
    reset,
  } = useForm<ChangePasswordFormValues>({
    resolver: changePasswordResolver,
    mode: 'onBlur',
  });

  const [newPassword, setNewPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);

  const { user } = useUser();

  useEffect(() => {
    if (!isOpen) {
      reset();
      setNewPassword('');
      setError('');
      setLoading(false);
    }
  }, [isOpen, reset]);

  const handleChangeField = (
    field: keyof ChangePasswordFormValues,
    value: string
  ) => {
    setValue(field, value.trim(), { shouldValidate: true });
  };

  const {
    alert: changePassword,
    setAlert: setChangePassword,
    closeAlert,
  } = useAlert();

  const onContinue = async (data: ChangePasswordFormValues) => {
    setLoading(true);
    try {
      await user?.updatePassword({
        currentPassword: data.currentPassword,
        newPassword: data.newPassword,
        signOutOfOtherSessions: true,
      });
      setLoading(false);
      onSave();
      closeModal();
      setChangePassword({
        isSuccess: true,
        message: ALERT_MESSAGES.UPDATE_PASSWORD,
      });
    } catch (err) {
      setLoading(false);
      setError('Failed to change password. Please try again.');
    }
  };

  return (
    <>
      {changePassword && (
        <Alert
          isSuccess={changePassword.isSuccess}
          message={changePassword.message}
          onClose={closeAlert}
        />
      )}
      <Modal
        className="w-[calc(100%-48px)] md:w-[634px] p-6 pt-8 md:p-8"
        isOpen={isOpen}
        closeModal={closeModal}
      >
        <form onSubmit={handleSubmit(onContinue)}>
          <div className="mb-8 md:mb-10">
            <div className="flex justify-between w-[100%] mb-2">
              <div />
              <p className="font-bold text-2xl">Change Password</p>
              <div className={'cursor-pointer'} onClick={closeModal}>
                <Icons.Cross />
              </div>
            </div>
            <p className="text-center text-[#4E4E4E] text-sm md:text-base font-normal">
              To change your password, please fill in the fields below.
            </p>
          </div>
          <div className="flex flex-col gap-4 mb-6">
            <InputField
              register={register}
              name="currentPassword"
              type="password"
              placeholder="Current Password"
              Icon={Icons.Password}
              onChange={e =>
                handleChangeField('currentPassword', e.target.value.trim())
              }
            />
            <InputField
              register={register}
              autoComplete="off"
              name="newPassword"
              type="password"
              placeholder="New Password"
              Icon={Icons.Password}
              onChange={e => {
                setNewPassword(e.target.value.trim());
                handleChangeField('newPassword', e.target.value.trim());
              }}
              errorMsg={errors.newPassword?.message}
            />
            <InputField
              register={register}
              autoComplete="off"
              name="confirmPassword"
              type="password"
              placeholder="Confirm Password"
              Icon={Icons.Password}
              onChange={e =>
                handleChangeField('confirmPassword', e.target.value.trim())
              }
              errorMsg={errors.confirmPassword?.message}
            />
            {error && <p className="text-[#B3001F] mt-1">{error}</p>}
          </div>
          <PasswordValidation password={newPassword} />
          <div className="flex flex-col justify-end w-100 gap-4 mt-4">
            <Button
              btnType="signUp"
              onClick={() => {}}
              disabled={!isValid}
              fullWidth
              variant="primary"
              label="Save"
              size="xl"
              loading={isLoading}
            />
            <Button
              btnType="signUp"
              onClick={closeModal}
              fullWidth
              variant="primary"
              label="Cancel"
              size="xl"
            />
          </div>
        </form>
      </Modal>
    </>
  );
};

export default ChangePasswordModal;
