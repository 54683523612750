import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSignIn } from '@clerk/clerk-react';
import { useForm } from 'react-hook-form';

import { InputField } from '../../components/shared/ui/InputField';
import { Button } from '../../components/shared/ui/Button';
import { Icons } from '../../helpers/icons';
import Alert from '../../components/shared/ui/Alert';
import useAlert from '../../CustomHooks/useAlert';
import { ALERT_MESSAGES } from '../../constants';
import { forgotPasswordResolver } from '../../validators/AuthValidators';
import PasswordValidation from '../../components/shared/ui/PassswordValidation';

interface PasswordPros {}

interface ChangePasswordFormValues {
  newPassword: string;
  confirmPassword: string;
}

const CreatePassword: React.FC<PasswordPros> = props => {
  const { signIn, setActive } = useSignIn();
  const [isError, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [passwordMismatchError, setPasswordMismatchError] =
    useState<boolean>(false);
  const [otp, setOtp] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [isLoading, setLoading] = useState<boolean>(false);

  const {
    alert: savePasswordAlert,
    setAlert: setSavePasswordAlert,
    closeAlert,
  } = useAlert();
  const location = useLocation();

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    setValue,
    watch,
  } = useForm<ChangePasswordFormValues>({
    resolver: forgotPasswordResolver,
    mode: 'onBlur',
  });

  const handleChangeField = (
    field: keyof ChangePasswordFormValues,
    value: string
  ) => {
    setValue(field, value.trim(), { shouldValidate: true });
  };

  useEffect(() => {
    if (location.state) {
      setOtp(location.state.otp);
    }
  }, [location]);

  const handlePasswordChange = async () => {
    setLoading(true);
    if (newPassword !== watch('confirmPassword')) {
      setPasswordMismatchError(true);
      setLoading(false);
      return;
    }
    setPasswordMismatchError(false);

    try {
      const result = await signIn?.attemptFirstFactor({
        strategy: 'reset_password_email_code',
        code: otp,
        password: newPassword,
      });

      if (result?.status === 'complete') {
        setSavePasswordAlert({
          isSuccess: true,
          message: ALERT_MESSAGES.CHANGE_PASSWORD_SUCCESS,
        });
        setActive!({ session: result.createdSessionId });
      } else {
        setSavePasswordAlert({
          isSuccess: false,
          message: ALERT_MESSAGES.INVALID_PASSWORD,
        });
      }
    } catch (errors: any) {
      if (errors && errors.length > 0) {
        console.log(errors);
        setErrorMessage(errors[0].long_message);
      } else {
        setError(true);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="content-container bg-[#F9F9F9]">
      {savePasswordAlert && (
        <Alert
          title="Success"
          isSuccess={savePasswordAlert?.isSuccess}
          message={savePasswordAlert?.message}
          onClose={closeAlert}
        />
      )}
      {errorMessage && (
        <Alert
          title="Error"
          isSuccess={false}
          message={errorMessage}
          onClose={() => setErrorMessage('')}
        />
      )}
      <form onSubmit={handleSubmit(handlePasswordChange)}>
        <div className="bg-white px-4 py-6 md:p-8 my-2 md:w-[1000px] mx-5 rounded-lg shadow-md max-w-xl border border-[#C9CBCD]">
          <h2 className="text-xl md:text-3xl font-bold md:mt-10 mb-1 tracking-wider">
            Create New Password
          </h2>
          <h1 className="text-xs md:text-base text-[#3F3F3F] mb-6 mt-4">
            {' '}
            New password must be different from previous used passwords.{' '}
          </h1>
          <div className="flex items-center space-y-2 flex-col justify-center">
            <InputField
              register={register}
              autoComplete="off"
              name="newPassword"
              type="password"
              placeholder="New Password"
              Icon={Icons.Password}
              onChange={e => {
                setNewPassword(e.target.value.trim());
                handleChangeField('newPassword', e.target.value.trim());
              }}
            />
            <InputField
              register={register}
              autoComplete="off"
              name="confirmPassword"
              type="password"
              placeholder="Confirm Password"
              Icon={Icons.Password}
              onChange={e =>
                handleChangeField('confirmPassword', e.target.value.trim())
              }
              errorMsg={errors.confirmPassword?.message}
            />
          </div>
          {isError && (
            <p className="mt-3 text-sm md:text-base text-red-10">
              {errorMessage}
            </p>
          )}
          {passwordMismatchError && (
            <p className="mt-3 text-sm md:text-base text-red-10">
              New password and confirm password do not match.
            </p>
          )}
          <PasswordValidation password={newPassword} />
          <div className="mt-20 mb-20">
            <Button
              btnType="signUp"
              onClick={() => {}}
              disabled={!isValid}
              fullWidth
              variant="primary"
              label="Reset Password"
              size="xl"
              loading={isLoading}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreatePassword;
