import React, { ReactNode } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import BackToHomeButton from '../../ui/BackToHomeButton';
import Sidebar from '../SideBar';

interface MainLayoutProps {
  children: ReactNode;
  className?: string;
  isSideBar?: boolean;
  showBackButton?: boolean;
}

const MainLayout: React.FC<MainLayoutProps> = ({
  children,
  showBackButton = false,
  className,
  isSideBar = true,
}) => {
  return (
    <div className="flex flex-col min-h-screen bg-[#F9F9F9]">
      {isSideBar && <Sidebar isMobile={false} />}
      <div className={`${isSideBar && 'md:ml-80'} flex flex-col flex-1`}>
        <Header />
        {showBackButton && <BackToHomeButton />}
        <div
          className={`flex flex-1 md:px-8 md:p-0 px-3 md:pt-0 ${className} `}
        >
          {children}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MainLayout;
