import React from 'react';

interface LoaderProps {
  size?: number;
  color?: string;
  stroke?: string;
  customStyle?: React.CSSProperties;
}

const Loader: React.FC<LoaderProps> = ({
  size = 80,
  color = '#176852',
  stroke,
  customStyle,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 200 200"
    width={size}
    height={size}
    style={customStyle}
  >
    <circle
      fill={color}
      stroke={stroke || color}
      strokeWidth="10"
      r="10"
      cx="40"
      cy="100"
    >
      <animate
        attributeName="opacity"
        calcMode="spline"
        dur="2"
        values="1;0;1;"
        keySplines=".5 0 .5 1;.5 0 .5 1"
        repeatCount="indefinite"
        begin="-.4"
      />
    </circle>
    <circle
      fill={color}
      stroke={stroke || color}
      strokeWidth="15"
      r="15"
      cx="100"
      cy="100"
    >
      <animate
        attributeName="opacity"
        calcMode="spline"
        dur="2"
        values="1;0;1;"
        keySplines=".5 0 .5 1;.5 0 .5 1"
        repeatCount="indefinite"
        begin="-.2"
      />
    </circle>
    <circle
      fill={color}
      stroke={stroke || color}
      strokeWidth="15"
      r="15"
      cx="160"
      cy="100"
    >
      <animate
        attributeName="opacity"
        calcMode="spline"
        dur="2"
        values="1;0;1;"
        keySplines=".5 0 .5 1;.5 0 .5 1"
        repeatCount="indefinite"
        begin="0"
      />
    </circle>
  </svg>
);

export default Loader;
