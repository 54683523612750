import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

interface ProtectedStripeRouteProps {
  component: React.ComponentType<any>;
}

const ProtectedStripeRoute: React.FC<ProtectedStripeRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const encodedParams = searchParams.get('params');
  const decodedParams = encodedParams ? atob(encodedParams) : '';
  const decodedSearchParams = new URLSearchParams(decodedParams);

  const fromStripe = decodedSearchParams.get('fromStripe') === 'true';
  const isTrial = decodedSearchParams.get('trial') === 'true';

  // Pass the parameters as props to the component
  return fromStripe ? (
    <Component {...rest} isTrial={isTrial} />
  ) : (
    <Navigate to="/" replace />
  );
};

export default ProtectedStripeRoute;
