import { useMutation, UseMutationResult, MutationFunction } from 'react-query';
import { AxiosInstance } from 'axios';

import { axiosEtsyInstance } from '../api/apiInstances';

interface GenericData {
  [key: string]: any;
}

const useGenericMutation = <
  TResponse,
  TError = unknown,
  TData extends GenericData = GenericData,
>(
  endpoint: string,
  method: 'POST' | 'PUT',
  axiosInstance: AxiosInstance = axiosEtsyInstance
): UseMutationResult<TResponse, TError, TData | undefined> => {
  const mutationFn: MutationFunction<TResponse, TData | undefined> = async (
    data?: TData,
    config?: any
  ) => {
    const response = await axiosInstance.request<TResponse>({
      url: endpoint,
      method: method,
      data: data,
      ...config,
    });
    return response.data;
  };

  return useMutation<TResponse, TError, TData | undefined>(mutationFn);
};

export default useGenericMutation;
