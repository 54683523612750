import React from 'react';

import { Icons } from '../../../helpers/icons';

const EmailSent: React.FC = () => {
  return (
    <div className="content-container bg-[#F9F9F9]">
      <div className="flex flex-col bg-white md:px-12 md:py-20 px-8 py-12 items-center justify-center rounded-lg shadow-md mx-5 border border-[#C9CBCD]">
        <h2 className="text-xl md:text-3xl font-bold mb-10 tracking-wider">
          Verification for Email.
        </h2>
        <div className="flex flex-col md:px-24 md:py-14 px-12 py-8 space-y-6 max-w-[560px] bg-gray-200 border border-black rounded-lg border-dashed items-center justify-center relative shadow-md ">
          <div>
            <Icons.EmailCheck />
          </div>
          <div className="md:text-lg text-sm text-gray-500 text-center">
            A Verification link is sent to your email. Please check your email
            to confirm your email.
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailSent;
