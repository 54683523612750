import React, { useEffect } from 'react';
import { Icons } from '../../../../helpers/icons';

interface AlertProps {
  isSuccess: boolean | undefined;
  message: string | undefined;
  title?: string;
  onClose: () => void;
}

const Alert: React.FC<AlertProps> = ({
  isSuccess,
  title,
  message,
  onClose,
}) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 3000);

    return () => clearTimeout(timer);
  }, [onClose]);

  const containerClasses = isSuccess
    ? 'border-green-700 bg-green-100'
    : 'border-red-700 bg-red-100';
  const textClasses = isSuccess ? 'text-green-700' : 'text-red-700';
  const IconComponent = isSuccess ? Icons.Check : Icons.Notification;

  return (
    <div
      className={`fixed border top-1 right-8 md:right-[80px] z-50 flex items-start justify-between p-4 rounded-md ${containerClasses}`}
      style={{
        width: 'auto',
        minWidth: '350px',
        maxWidth: 'calc(100vw - 16px)',
      }}
    >
      <div className="flex items-start">
        <IconComponent className={`mr-2 ${textClasses}`} />
        <div className="flex flex-col">
          <div
            className={`flex-grow text-base font-semibold mb-[9px] ${textClasses}`}
          >
            {title ?? 'Message'}
          </div>
          <div className="text-xs md:text-sm whitespace-normal">{message}</div>
        </div>
      </div>
      <button
        className={` text-lg hover:text-green-900 focus:outline-none ${textClasses}`}
        onClick={onClose}
      >
        <Icons.Cross />
      </button>
    </div>
  );
};

export default Alert;
