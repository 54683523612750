/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import OTPInput from 'react-otp-input';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSignIn } from '@clerk/clerk-react';

import { Button } from '../../components/shared/ui/Button';
import { formatEmail } from '../../helpers/utils';

interface OTPPros {}

const VerifyOTP: React.FC<OTPPros> = props => {
  const [otp, setOtp] = useState('');
  const [email, setEmail] = useState('');
  const [timer, setTimer] = useState(60);
  const [isTimerRunning, setIsTimerRunning] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const { signIn } = useSignIn();
  const [error, setError] = useState('');

  useEffect(() => {
    if (location.state) {
      setEmail(location.state.email);
      startTimer();
    }
  }, [location]);

  const startTimer = () => {
    setIsTimerRunning(true);
    const interval = setInterval(() => {
      setTimer(timer => timer - 1);
      if (timer < 0) {
        clearInterval(interval);
        setIsTimerRunning(false);
      }
    }, 1000);
    return () => clearInterval(interval);
  };

  const handleOTP = async () => {
    navigate('/new-password', { state: { otp: otp } });
  };

  const resendOTP = async () => {
    try {
      await signIn?.create({
        strategy: 'reset_password_email_code',
        identifier: email,
      });
    } catch (err) {
      setError('Failed to send reset code. Please try again.');
    }
  };

  return (
    <div className="content-container bg-[#F9F9F9]">
      <div className="bg-white px-4 py-6 md:p-8 my-2 md:min-w-[769px] mx-5 rounded-lg shadow-md max-w-xl border border-[#C9CBCD]">
        <h2 className="text-xl md:text-3xl font-bold md:mt-10 mb-1 tracking-wider">
          Verify Code
        </h2>
        <h1 className="text-[#3F3F3F] md:text-base text-sm mb-6 mt-4">
          Enter the 6-digit code sent to {formatEmail(email)}.
        </h1>
        <div className="flex items-center flex-col justify-center">
          <OTPInput
            value={otp}
            onChange={value => setOtp(value)}
            numInputs={6}
            renderInput={props => <input {...props} />}
            skipDefaultStyles
            containerStyle="space-x-5"
            inputStyle="border border-#E3E3E3 text-xl rounded-lg text-center h-[50px] w-[50px] md:h-[74px] md:w-[94px] w-full"
            placeholder="______"
          />

          <p className="mt-8 text-[#4F4F4F]">Didn’t receive a code?</p>
          <p className="mt-2">
            {isTimerRunning && timer > 0 ? (
              `Please wait ${timer < 10 ? '0' : ''}${Math.floor(timer / 60)}:${timer % 60 < 10 ? '0' : ''}${timer % 60}`
            ) : (
              <span
                onClick={resendOTP}
                className="text-etsyPrimary font-semibold underline cursor-pointer"
              >
                Resend OTP
              </span>
            )}
          </p>
        </div>

        <div className="mt-20 mb-40">
          <Button
            btnType="signUp"
            onClick={handleOTP}
            disabled={otp.length < 6}
            fullWidth
            variant="primary"
            label="Verify"
          />
        </div>
        {error && <p className="text-[#B3001F]">{error}</p>}
      </div>
    </div>
  );
};

export default VerifyOTP;
