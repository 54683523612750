import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface StripeState {
  stripeUrl?: string | null;
  selectedPlan?: string | null;
  stripeSessionId?: string | null;
}

const initialState: StripeState = {
  stripeUrl: null,
  selectedPlan: null,
  stripeSessionId: null,
};

interface StripePayload {
  stripeUrl?: string | null;
  selectedPlan?: string | null;
  stripeSessionId?: string | null;
}

const stripeSlice = createSlice({
  name: 'stripe',
  initialState,
  reducers: {
    setStripeData: (state, action: PayloadAction<StripePayload>) => {
      state.stripeUrl = action.payload.stripeUrl;
      state.selectedPlan = action.payload.selectedPlan;
    },
    setStripeSessionId: (state, action: PayloadAction<string>) => {
      state.stripeSessionId = action.payload;
    },
  },
});

export const { setStripeData, setStripeSessionId } = stripeSlice.actions;
export default stripeSlice.reducer;
