import React from 'react';

import './index.css';

const Loader: React.FC = () => {
  return (
    <div className="dot-loader">
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
    </div>
  );
};

export default Loader;
