import React, { useMemo } from 'react';
import { Icons } from '../../../../helpers/icons';

interface PasswordValidationProps {
  password: string;
}

const PasswordValidation: React.FC<PasswordValidationProps> = ({
  password,
}) => {
  const validations = useMemo(
    () => [
      {
        id: 'minLength',
        label: 'Password must contain min 8 characters',
        valid: password?.length >= 8,
      },
      {
        id: 'maxLength',
        label: 'Password can have max 20 characters',
        valid: password?.length <= 20,
      },
      {
        id: 'specialCharacter',
        label: 'Password must contain a special character',
        valid: /[!@#$%^&*(),.?":{}|<>]/.test(password),
      },
      {
        id: 'numeric',
        label: 'Password must have at least 1 numeric character',
        valid: /\d/.test(password),
      },
      {
        id: 'upperLowerCase',
        label: 'Password must have both lower-case and upper-case alphabet',
        valid: /[a-z]/.test(password) && /[A-Z]/.test(password),
      },
    ],
    [password]
  );

  return (
    <div className="mt-4">
      {validations.map(validation => (
        <div
          key={validation.id}
          className="flex items-center text-base space-x-2 mb-2"
        >
          {validation.valid ? (
            <Icons.GreenCheck className="text-green-500" />
          ) : (
            <Icons.RedCheck className="text-[#B3001F]" />
          )}
          <div className="md:text-sm text-xs">{validation.label}</div>
        </div>
      ))}
    </div>
  );
};

export default PasswordValidation;
