import { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUser } from '@clerk/clerk-react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  AltTextResponse,
  ChatMessage,
  DescriptionResponse,
  DuplicateResponse,
  EtsySessionResponse,
  GeneratedData,
  KeywordsResponse,
  LoadedGenerationResponse,
  LoadedSessionResponse,
  TagsResponse,
  TitleResponse,
} from '../common/types';
import useGenericMutation from './useMutation';
import useAlert from './useAlert';
import { ALERT_MESSAGES } from '../constants';
import { RootState, store } from '../redux/store';

import { setSessionID } from '../redux/feature/SessionSlice';
import {
  axiosEtsyInstance,
  axiosStripeInstance,
  useAxiosInterceptors,
  useAxiosStripeInterceptors,
} from '../api/apiInstances';
import { setLisitngId } from '../redux/feature/ListingSlice';
import { ChatComponentRef } from '../components/Home/ChatBot';
import { DragAndDropRef } from '../components/Home/DragNDrop';
import { HistorySelectionRef } from '../components/Home/HistorySelection';
import { setKeywords } from '../redux/feature/KeywordsSlice';
import { UserProfile } from '../common/types/userProfile';
import { CheckoutSessionResponse } from '../common/types/stripeSessions';
import { setStripeData } from '../redux/feature/StripeSlice';
import { setHistoryEnabled } from '../redux/feature/HistoryEnableSlice';

const useHomeState = () => {
  const { user } = useUser();
  useAxiosInterceptors();
  useAxiosStripeInterceptors();
  const sessionID = useSelector((state: RootState) => state.session.sessionID);
  const listingID = useSelector((state: RootState) => state.listing.listingId);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const encodedSessionId = queryParams.get('sessionId');
  const encodedListingId = queryParams.get('listingId');
  const duplicate = queryParams.get('duplicate');
  const locationSessionId = encodedSessionId ? atob(encodedSessionId) : null;
  const locationListingId = encodedListingId ? atob(encodedListingId) : null;

  const [isKeywordGenerateButtonEnabled, setKeywordGenerateButton] =
    useState<boolean>(false);
  const [titleFromUrl, setTitleFromUrl] = useState<string | null>(null);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [isTitleGenerated, setTitleGenerated] = useState<boolean>(false);
  const [isAltCardGenerated, setAltCardGenerated] = useState<boolean>(false);
  const [generatedAltText, setGeneratedAltText] = useState<AltTextResponse>();
  const [generatedAltDescription, setGeneratedAltDescription] =
    useState<string>('');
  const [generatedAltTags, setGeneratedAltTags] = useState<string[]>();
  const [generatedCustomKeywords, setGeneratedCustomKeywords] = useState<
    string[]
  >([]);

  const [imageDescription, setImageDescription] = useState<string | null>(null);
  const [generatedContext, setGeneratedContext] = useState<string | null>(null);
  const [customInstructions, setCustomInstructions] = useState<string | null>(
    null
  );
  const [imgBase64, setImgBase64] = useState<string | null>(null);
  const [isImageError, setImageError] = useState<boolean>(false);
  const [isImageUploaded, setImageUploaded] = useState<boolean>(false);
  const [generatedData, setGeneratedData] = useState<GeneratedData>({
    altText: null,
    description: null,
    tags: null,
  });
  const [generatedTitles, setGeneratedTitles] = useState<TitleResponse | null>(
    null
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [titlePick, setTitlePick] = useState<string | null>(null);
  const [isNewListing, setNewListing] = useState<boolean>(false);
  const [listingConsumedModalOpened, setListingConsumedModalOpened] =
    useState<boolean>(false);
  const [purchaseTrialModalOpened, setPurchaseTrialModalOpened] =
    useState<boolean>(false);
  const [consumedTrialModalOpened, setConsumedTrialModalOpened] =
    useState<boolean>(false);
  const [plansModalOpened, setPlansModalOpened] = useState(false);
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);
  const [loadingSession, setLoadingSession] = useState<boolean>(false);
  const [disableAllButtons, setDisableAllButtons] = useState<boolean>(false);
  const [listingName, setListingName] = useState<string>('');
  const [disableAltGenerationButton, setDisableAltGenerationButton] =
    useState<boolean>(false);

  const [isDuplicate, setIsDuplicate] = useState<boolean>(false);
  const [listingNotCompleteModal, setListingNotCompleteModal] =
    useState<boolean>(false);
  const [bypassIncompleteListingCheck, setBypassIncompleteListingCheck] =
    useState<boolean>(false);
  const [remainingListings, setRemainingListings] = useState<number>(0);
  const [loadingRemainingListings, setLoadingRemainingListings] =
    useState<boolean>(false);
  const [loadedChatMessages, setLoadedChatMessages] = useState<ChatMessage[]>(
    []
  );
  const [isNewListingLoading, setIsNewListingLoading] =
    useState<boolean>(false);
  const [showScrollToBottomButton, setShowScrollToBottomButton] =
    useState<boolean>(false);

  const { alert, setAlert, closeAlert } = useAlert();
  const dispatch = useDispatch();
  const { mutateAsync: startSession } = useGenericMutation<EtsySessionResponse>(
    '/session',
    'POST'
  );

  const { mutateAsync: saveUserInstructions } = useGenericMutation<any>(
    `/session/${sessionID}`,
    'PUT'
  );

  const { mutateAsync: generateCustomKeywords, isError: customKeywordsError } =
    useGenericMutation<KeywordsResponse>(
      `/generate/${sessionID}/custom_keywords`,
      'POST'
    );
  const { mutateAsync: generateAltText, isError: altTextError } =
    useGenericMutation<AltTextResponse>(
      `/generate/${sessionID}/alt_text`,
      'POST'
    );
  const { mutateAsync: generateDescription, isError: descriptionError } =
    useGenericMutation<DescriptionResponse>(
      `/generate/${sessionID}/description`,
      'POST'
    );
  const { mutateAsync: generateTags, isError: tagError } =
    useGenericMutation<TagsResponse>(`/generate/${sessionID}/tags`, 'POST');
  const { mutateAsync: getTitles, isError: titleError } =
    useGenericMutation<any>(`/generate/${sessionID}/title`, 'POST');

  const { mutateAsync: updateUserProfile } = useGenericMutation<any>(
    '/users/profile',
    'PUT',
    axiosStripeInstance
  );

  const { mutateAsync: createCheckoutSession } =
    useGenericMutation<CheckoutSessionResponse>(
      '/subscription/create-checkout-session',
      'POST',
      axiosStripeInstance
    );

  const navigate = useNavigate();

  const successPath = `/stripe-success?params=${btoa('fromStripe=true')}`;
  const cancelPath = `/stripe-cancel?params=${btoa('fromStripe=true')}`;

  const handleStartTrial = async () => {
    const trialResponse =
      await axiosStripeInstance.get<any>(`/subscription/trial`);

    const response = await createCheckoutSession({
      planId: trialResponse?.data?.id,
      userId: user?.id,
      successUrl: `${window.location.origin}${successPath}`,
      cancelUrl: `${window.location.origin}${cancelPath}`,
      planType: trialResponse?.data.type,
    });
    dispatch(
      setStripeData({
        stripeUrl: response.paymentUrl,
        selectedPlan: trialResponse?.data?.id,
      })
    );
    window.open(response.paymentUrl, '_blank');
  };

  const fetchDuplicateSessionData = useCallback(async () => {
    try {
      const duplicateResponse = await axiosEtsyInstance.post(
        `/listing/${locationListingId}/duplicate`
      );

      dispatch(setSessionID(duplicateResponse.data.session.id));
      dispatch(setLisitngId(duplicateResponse.data.listing.id));

      handleDuplicateLoadSession(duplicateResponse.data);

      navigate('/home', { replace: true });
    } catch (error) {
      console.log(error);
      setAlert({
        isSuccess: false,
        message: ALERT_MESSAGES.SESSION_LOAD_FAILED,
      });
    }
  }, [dispatch, locationListingId, navigate, setAlert]);

  const fetchSessionViewData = useCallback(
    async (flag: boolean = false) => {
      try {
        const sessionEndpointId = locationSessionId
          ? locationSessionId
          : sessionID;
        const listingEndpointId = locationListingId
          ? locationListingId
          : listingID;

        if (!sessionEndpointId || !listingEndpointId) {
          console.error('Missing required IDs for session view');
          return;
        }

        const [sessionResponse, generationResponse] = await Promise.all([
          axiosEtsyInstance.get<LoadedSessionResponse>(
            `/session/${sessionEndpointId}`
          ),
          axiosEtsyInstance.get<LoadedGenerationResponse>(
            `/listing/${listingEndpointId}`
          ),
        ]);

        if (flag && generationResponse.data.alt_text) {
          dispatch(setSessionID(null));
          dispatch(setLisitngId(null));
          return;
        }

        dispatch(setSessionID(sessionEndpointId));
        dispatch(setLisitngId(listingEndpointId));
        if (
          generationResponse.data.title === null &&
          generationResponse.data.is_original
        ) {
          setDisableButton(!sessionResponse.data.chat_complete);
        }

        if (
          generationResponse.data.title ||
          generationResponse.data.title != null
        ) {
          setDisableAllButtons(true);
          setDisableAltGenerationButton(!!generationResponse.data.description);
        }
        const loadedListings = sessionResponse.data;
        const loadedGeneration = generationResponse.data;

        if (loadedGeneration.is_duplicated && !loadedGeneration.is_original) {
          setIsDuplicate(true);
        }
        handleLoadViewSession({ loadedListings, loadedGeneration });

        navigate('/home', { replace: true });
      } catch (error) {
        console.log(error);
        setAlert({
          isSuccess: false,
          message: ALERT_MESSAGES.SESSION_LOAD_FAILED,
        });
      }
    },
    [
      locationSessionId,
      sessionID,
      locationListingId,
      listingID,
      dispatch,
      navigate,
      setAlert,
    ]
  );

  useEffect(() => {
    if (
      sessionID !== null &&
      listingID !== null &&
      duplicate === null &&
      locationSessionId === null
    ) {
      fetchSessionViewData(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  useEffect(() => {
    const handleScroll = () => {
      setShowScrollToBottomButton(window.scrollY > 300);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const { isCheckingOut } = useSelector((state: RootState) => state.checkOut);

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (user?.id) {
        try {
          const response = await axiosStripeInstance.get<UserProfile>(
            `/users/${user.id}`
          );
          setUserProfile(response.data);
        } catch (error) {
          console.error('Failed to fetch user profile:', error);
        }
      }
    };

    fetchUserProfile();
  }, [user?.id]);

  useEffect(() => {
    if (locationSessionId && duplicate) {
      setLoadingSession(true);
      fetchDuplicateSessionData();
    } else if (locationSessionId && !duplicate) {
      setLoadingSession(true);
      fetchSessionViewData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duplicate]);

  useEffect(() => {
    if (customKeywordsError) {
      setAlert({
        isSuccess: false,
        message: ALERT_MESSAGES.CUSTOM_KEYWORDS_NOT_GENERATED,
      });
    } else if (altTextError) {
      setAlert({
        isSuccess: false,
        message: ALERT_MESSAGES.ALT_TEXT_NOT_GENERATED,
      });
    } else if (descriptionError) {
      setAlert({
        isSuccess: false,
        message: ALERT_MESSAGES.DESCRIPTION_NOT_GENERATED,
      });
    } else if (tagError) {
      setAlert({
        isSuccess: false,
        message: ALERT_MESSAGES.TAGS_NOT_GENERATED,
      });
    } else if (titleError) {
      setAlert({
        isSuccess: false,
        message: ALERT_MESSAGES.TITLES_NOT_GENERATED,
      });
    }
  }, [
    customKeywordsError,
    altTextError,
    descriptionError,
    tagError,
    titleError,
    setAlert,
  ]);

  useEffect(() => {
    setLoadingRemainingListings(true);
    const fetchSubscription = async () => {
      const subscriptionResponse = await axiosStripeInstance.get<any>(
        `/subscription/all-subscriptions?userId=${user?.id}`
      );
      const activeSubscription = subscriptionResponse.data.basicPlans.find(
        (subscription: any) => subscription.status === 'active'
      );
      setRemainingListings(activeSubscription?.remainingListings);
      setLoadingRemainingListings(false);
    };
    fetchSubscription();
  }, [user?.id]);

  const handleImageDescription = (
    description: string | null,
    imgBase64: string | null,
    isError?: boolean
  ) => {
    setImageDescription(description);
    setImgBase64(imgBase64);
    isError && setImageError(true);
  };

  const notifyChatBot = (flag: boolean) => {
    setImageUploaded(flag);
  };

  const handleChatEnd = (
    endBool: boolean,
    generated_context: string | null
  ) => {
    setGeneratedContext(generated_context);
    setKeywordGenerateButton(endBool);
    setDisableButton(false);
  };

  const startEtsySession = async () => {
    try {
      const response = await startSession({});
      setListingName(response.listing?.name || 'Untitled');
      store.dispatch(setSessionID(response.session.id));
      store.dispatch(setLisitngId(response.listing.id));
      await updateUserProfile({ trialCompleted: true });
    } catch (error) {
      const profileResponse = await axiosStripeInstance.get<UserProfile>(
        `/users/${user?.id}`
      );
      const { trialCompleted, planPurchased } =
        profileResponse.data || userProfile;

      if (!trialCompleted) {
        setPurchaseTrialModalOpened(true);
      } else if (trialCompleted && !planPurchased) {
        setConsumedTrialModalOpened(true);
      } else if (planPurchased) {
        setListingConsumedModalOpened(true);
      }
    } finally {
      setNewListing(true);
    }
  };

  const handleGenerateGeneration = async () => {
    setIsLoading(true);
    try {
      if (customInstructions) {
        await saveUserInstructions({
          user_instructions: customInstructions,
        });
      }
      let customKeywordsResponse;

      customKeywordsResponse = await generateCustomKeywords({});
      setGeneratedCustomKeywords(customKeywordsResponse.keywords);
      setAltCardGenerated(true);
      dispatch(setKeywords(customKeywordsResponse.keywords));

      if (customKeywordsResponse?.keywords) {
        const titlesResponse = await getTitles({});
        setTitleGenerated(true);
        setGeneratedTitles(titlesResponse);
        setAlert({
          isSuccess: true,
          message: ALERT_MESSAGES.TITLE_GENERATED_SUCCESS,
        });
      }
      //NOTE: Disable button after title generation
      setDisableButton(true);
    } catch (error) {
      setAlert({
        isSuccess: false,
        message: ALERT_MESSAGES.GENERATION_GENERATION_FAILED,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleAltCardGeneration = async () => {
    try {
      const altTextResponse = await generateAltText({});
      setAltCardGenerated(true);
      setGeneratedAltText(altTextResponse);
      const descriptionResponse = await generateDescription({});
      setGeneratedAltDescription(descriptionResponse.description);
      const tagsResponse = await generateTags({});
      setGeneratedAltTags(tagsResponse.tags);
      setDisableAltGenerationButton(true);
      dispatch(setHistoryEnabled(true));
    } catch (error) {
      setAlert({
        isSuccess: false,
        message: ALERT_MESSAGES.ALT_CARD_DATA_GENERATION_FAILED,
      });
    } finally {
      setAltCardGenerated(true);
    }
  };

  const handleDuplicateLoadSession = (duplicateResponse: DuplicateResponse) => {
    setNewListing(true);

    setKeywordGenerateButton(duplicateResponse.session.chat_complete);
    setImageDescription(duplicateResponse.session.chat_summary?.summary);
    setImgBase64(duplicateResponse.session.img_url);
    setGeneratedContext(duplicateResponse.session.chat_summary?.summary);
    setCustomInstructions(duplicateResponse.session.user_instructions);
    setLoadingSession(false);
    setListingName(duplicateResponse.listing?.name || '');
    setIsDuplicate(true);
  };

  const handleLoadViewSession = ({
    loadedListings,
    loadedGeneration,
  }: {
    loadedListings: LoadedSessionResponse;
    loadedGeneration: LoadedGenerationResponse;
  }) => {
    setNewListing(true);
    const isTitleGenerated = loadedGeneration.title?.titles?.length > 0;
    const isAltCardGenerated =
      (loadedGeneration.alt_text?.alt_text?.length ?? 0) > 0 ||
      (loadedGeneration.description?.length ?? 0) > 0 ||
      (loadedGeneration.tags?.length ?? 0) > 0;

    if (isAltCardGenerated) {
      setGeneratedData({
        altText: loadedGeneration.alt_text,
        description: { description: loadedGeneration.description },
        tags: { tags: loadedGeneration.tags },
      });
    }
    setGeneratedAltTags(loadedGeneration.tags);
    if (isTitleGenerated) {
      setGeneratedTitles(loadedGeneration.title);
      setTitlePick(
        loadedGeneration.title.titles[loadedGeneration.title.selected]
      );
    }

    if (loadedGeneration.keywords) {
      setGeneratedCustomKeywords(loadedGeneration.keywords);
    }
    setTitleGenerated(isTitleGenerated);
    setAltCardGenerated(isAltCardGenerated);
    setKeywordGenerateButton(true);
    setImgBase64(loadedListings.img_url);
    setGeneratedContext(loadedListings.chat_summary?.summary);
    setCustomInstructions(loadedListings.user_instructions);
    setListingName(loadedGeneration.name || '');
    setLoadingSession(false);

    if (loadedListings.chat_messages) {
      setLoadedChatMessages(loadedListings.chat_messages);
    }

    if (loadedGeneration.is_duplicated) {
      setAltCardGenerated(true);
    }
  };
  const chatRef = useRef<ChatComponentRef>(null);
  const dragAndDropRef = useRef<DragAndDropRef>(null);
  const selectionRef = useRef<HistorySelectionRef>(null);

  const resetAll = () => {
    setKeywordGenerateButton(false);
    setTitleGenerated(false);
    setAltCardGenerated(false);
    setGeneratedAltText(undefined);
    setGeneratedAltDescription('');
    setGeneratedAltTags(undefined);
    setGeneratedCustomKeywords([]);
    setImageDescription('');
    setGeneratedContext(null);
    setCustomInstructions('');
    setImgBase64(null);
    setImageError(false);
    setImageUploaded(false);
    setGeneratedData({
      altText: null,
      description: null,
      tags: null,
    });
    setGeneratedTitles(null);
    setIsLoading(false);
    setTitlePick(null);
    setAlert(null);
    dispatch(setHistoryEnabled(false));
    setDisableAllButtons(false);
    setIsDuplicate(false);
    setListingName('');
    setTitleGenerated(false);
    setDisableAllButtons(false);
    setDisableAltGenerationButton(false);

    if (chatRef.current) {
      chatRef.current.resetChat();
    }
    if (dragAndDropRef.current) {
      dragAndDropRef.current.resetDragAndDrop();
    }
  };

  const createNewListing = async () => {
    setIsNewListingLoading(true);
    dispatch(setSessionID(null));
    await startEtsySession();
    setIsNewListingLoading(false);
  };

  const createNewListingDuringSession = async (bypasss: boolean) => {
    if (!generatedAltTags && !bypasss) {
      setListingNotCompleteModal(true);
      return;
    }
    setIsNewListingLoading(true);

    try {
      const subscriptionResponse = await axiosStripeInstance.get<any>(
        `/subscription/all-subscriptions?userId=${user?.id}`
      );
      const activeSubscription = subscriptionResponse.data.basicPlans.find(
        (subscription: any) => subscription.status === 'active'
      );
      if (activeSubscription?.remainingListings === 0) {
        setListingConsumedModalOpened(true);
      } else if (
        activeSubscription &&
        activeSubscription.remainingListings > 0
      ) {
        resetAll();
        dispatch(setSessionID(null));
        await startEtsySession();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setBypassIncompleteListingCheck(false);
      setIsNewListingLoading(false);
    }
  };

  return {
    isKeywordGenerateButtonEnabled,
    isTitleGenerated,
    disableButton,
    titleFromUrl,
    isAltCardGenerated,
    imageDescription,
    generatedContext,
    customInstructions,
    imgBase64,
    isImageUploaded,
    generatedData,
    generatedTitles,
    isLoading,
    titlePick,
    alert,
    chatRef,
    dragAndDropRef,
    selectionRef,
    setDisableButton,
    setTitleFromUrl,
    setCustomInstructions,
    handleImageDescription,
    notifyChatBot,
    handleChatEnd,
    handleGenerateGeneration,
    handleAltCardGeneration,
    handleDuplicateLoadSession,
    resetAll,
    setAlert,
    closeAlert,
    isNewListing,
    setNewListing,
    loadingSession,
    createNewListing,
    generatedAltText,
    generatedAltDescription,
    generatedAltTags,
    generatedCustomKeywords,
    startEtsySession,
    isImageError,
    listingConsumedModalOpened,
    setListingConsumedModalOpened,
    plansModalOpened,
    setPlansModalOpened,
    purchaseTrialModalOpened,
    setPurchaseTrialModalOpened,
    consumedTrialModalOpened,
    setConsumedTrialModalOpened,
    handleStartTrial,
    createNewListingDuringSession,
    isCheckingOut,
    fetchSessionViewData,
    disableAllButtons,
    listingName,
    disableAltGenerationButton,
    isDuplicate,
    listingNotCompleteModal,
    setListingNotCompleteModal,
    setBypassIncompleteListingCheck,
    bypassIncompleteListingCheck,
    remainingListings,
    loadingRemainingListings,
    loadedChatMessages,
    isNewListingLoading,
    showScrollToBottomButton,
    scrollToTop,
  };
};

export default useHomeState;
