import { FC, useState } from 'react';
import { InputFieldProps } from '../../../../common/types/input';
import { Icons } from '../../../../helpers/icons';

export const InputField: FC<InputFieldProps> = props => {
  const {
    label,
    placeholder,
    type = 'text',
    errorMsg = '',
    name,
    register,
    required,
    inputLabelStyle,
    parentClass,
    inputClassName,
    inputContainerClass,
    onChange,
    onKeyUp,
    Icon,
    endIcon,
    autoCompleteValue,
    ...rest
  } = props;
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (type === 'number') {
      const numericValue = value.replace(/\D/g, '').slice(0, 5);
      event.target.value = numericValue;
    }

    if (onChange) {
      onChange(event);
    }
  };

  return (
    <div className={`w-full relative ${parentClass}`}>
      <div
        className={` ${
          inputLabelStyle
            ? 'text-[#182230] text-sm not-italic font-medium '
            : 'mb-2.5 text-gray-900 font-normal text-[14px] '
        }`}
      >
        <span>{label}</span>
        {required && <span className="text-red-700 text-[14px] ml-0.5">*</span>}
      </div>
      <div
        className={`flex items-center rounded-lg border ${
          errorMsg ? 'border-[#EB003B]' : 'border-gray-300'
        } relative w-auto ${inputContainerClass}`}
      >
        {Icon && (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center">
            <Icon />
          </div>
        )}
        <input
          {...rest}
          placeholder={placeholder}
          {...register?.(name)}
          type={showPassword && type === 'password' ? 'text' : type}
          onChange={handleChange}
          onKeyUp={onKeyUp}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          data-form-type="other"
          name={`${name}-no-autofill`}
          className={`w-full focus:outline-none rounded-lg text-gray-900 placeholder-gray-500 py-2 placeholder:text-[14px] placeholder:font-light ${inputClassName} ${
            Icon ? 'pl-10' : 'pl-2'
          }`}
        />
        {endIcon && (
          <div className="absolute inset-y-0 right-0 pr-2 flex items-center">
            {endIcon}
          </div>
        )}
        {type === 'password' && (
          <div className="absolute inset-y-0 right-0 pr-2 flex items-center">
            <img
              onClick={() => setShowPassword(!showPassword)}
              src={showPassword ? Icons.eyeOn : Icons.eyeOff}
              alt=""
            />
          </div>
        )}
      </div>
      {errorMsg && (
        <span className="text-[#EB003B] md:max-w-full max-w-[352px] text-sm mt-1 text-left font-light flex items-center">
          <Icons.Error className="mr-1" />
          {errorMsg}
        </span>
      )}
    </div>
  );
};
