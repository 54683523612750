/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, ReactNode } from 'react';

import { Icons } from '../../../../helpers/icons';
import { copyToClipboard } from '../../../../helpers/utils';
import Alert from '../Alert';

interface InputCardProps {
  title?: string;
  initialValue?: string;
  placeHolder?: string;
  maxLength?: number;
  maxWords?: number;
  validationType?: 'maxlength' | 'maxwords';
  inputType?: 'textarea' | 'input';
  icon?: ReactNode;
  totalWords?: number;
  onCopy?: (value: string) => void;
  onSave?: (value: string) => void;
  onChange?: (value: string) => void;
  className?: string;
  areaClassName?: string;
  headingClassName?: string;
  inputClassName?: string;
  showCopyButton?: boolean;
  showSaveButton?: boolean;
  showCharacterCount?: boolean;
  isKeywords?: boolean;
}

export const InputCard: React.FC<InputCardProps> = ({
  title,
  initialValue = '',
  maxLength = 140,
  maxWords,
  validationType = 'maxlength',
  inputType = 'textarea',
  placeHolder = '',
  icon = <Icons.Copy className="mr-1" />,
  onCopy = copyToClipboard,
  onSave,
  areaClassName,
  onChange = () => {},
  className = '',
  headingClassName = 'text-base md:text-2xl ',
  inputClassName = '',
  showCopyButton = true,
  showSaveButton = false,
  showCharacterCount = true,
  totalWords,
  isKeywords = false,
}) => {
  const [value, setValue] = useState<string>(initialValue);
  const [alert, setAlert] = useState<{
    isSuccess: boolean;
    message: string;
  } | null>(null);
  const [isChanged, setChanged] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(
    null
  );

  const inputRef = useRef<HTMLTextAreaElement | HTMLInputElement>(null);

  useEffect(() => {
    if (inputType === 'textarea' && inputRef.current) {
      inputRef.current.style.height = 'auto';
      inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
    }
  }, [value, inputType]);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    let saveTimeout: NodeJS.Timeout;
    if (isChanged && !typingTimeout) {
      saveTimeout = setTimeout(() => {
        handleSave();
      }, 3000);
    }

    return () => {
      if (saveTimeout) {
        clearTimeout(saveTimeout);
      }
    };
  }, [isChanged, typingTimeout]);

  const handleInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const newValue = event.target.value;
    let isValid = true;

    if (validationType === 'maxwords' && maxWords) {
      const wordCount = newValue.trim().split(',').length;
      if (wordCount > maxWords) {
        isValid = false;
        setError(`The input exceeds the maximum allowed words (${maxWords}).`);
      } else {
        setError(null);
      }
    } else if (validationType === 'maxlength' && maxLength) {
      if (newValue.length > maxLength) {
        isValid = false;
        setError(
          `The input exceeds the maximum allowed characters (${maxLength}).`
        );
      } else {
        setError(null);
      }
    }

    if (isValid) {
      setValue(newValue);
      onChange(newValue);
      setChanged(true);

      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
      setTypingTimeout(
        setTimeout(() => {
          setTypingTimeout(null);
        }, 2000)
      );
    }
  };

  const handleSave = () => {
    if (onSave) {
      setChanged(false);
      onSave(value);
    }
  };

  const handleCopy = () => onCopy(value, setAlert);
  const closeAlert = () => {
    setAlert(null);
  };

  const renderWarning = () => {
    if (isKeywords) {
      return (
        <div className="flex items-center justify-center rounded-md bg-[#176852] bg-opacity-15 text-[#176852] px-4 py-2 text-sm my-5">
          <Icons.GreenInfo className="w-5 h-5 mr-2" />
          <p>
            These keywords are not intended for direct use in your Etsy listing.
            Feel free to utilize them for blogs, websites, or other marketing
            channels as needed.
          </p>
        </div>
      );
    }
  };

  return (
    <div className={`w-full mx-auto ${className}`}>
      {alert && (
        <Alert
          isSuccess={alert.isSuccess}
          message={alert.message}
          onClose={closeAlert}
        />
      )}
      <h2 className={`font-bold mb-2 ${headingClassName}`}>{title}</h2>
      <div
        className={`border bg-white rounded-[10px] gray-300 p-4 md:p-5 ${areaClassName}`}
      >
        <div className="mb-2 flex justify-between items-start">
          {inputType === 'textarea' ? (
            <textarea
              ref={inputRef as React.RefObject<HTMLTextAreaElement>}
              className={`w-full border-none outline-none text-sm leading-6 bg-transparent resize-none overflow-hidden ${inputClassName}`}
              placeholder={placeHolder}
              value={value}
              onChange={handleInputChange}
            />
          ) : (
            <input
              ref={inputRef as React.RefObject<HTMLInputElement>}
              className={`w-full border-none outline-none text-sm leading-6 bg-transparent ${inputClassName}`}
              value={value}
              onChange={handleInputChange}
              type="text"
            />
          )}
        </div>

        {error && <div className="text-red-500 text-sm mt-1">{error}</div>}
        {renderWarning()}
        {showCopyButton && (
          <div className="flex justify-end space-x-5 mt-3">
            {/* {showSaveButton && (
              <button
                className={`text-sm font-medium flex items-center ${isChanged ? 'text-etsyPrimary' : 'text-[#868686]'}`}
                onClick={isChanged ? handleSave : undefined}
              >
                {isChanged ? (
                  <Icons.Save className="mr-1" />
                ) : (
                  <Icons.SaveDisabled className="mr-1 disabled" />
                )}
                Save
              </button>
            )} */}
            <button
              className="text-etsyPrimary text-sm font-medium flex items-center"
              onClick={handleCopy}
            >
              {icon}
              Copy
            </button>
          </div>
        )}
      </div>
      {showCharacterCount && (
        <div className="text-right text-gray-400 text-sm mt-1">
          {validationType === 'maxlength'
            ? `${value.length} / ${maxLength}`
            : `${value.trim().split(',').length} / ${maxWords} words`}
        </div>
      )}
    </div>
  );
};
