import React, { useEffect, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Icons } from '../../../../helpers/icons';
import { setLisitngId } from '../../../../redux/feature/ListingSlice';
import { setSessionID } from '../../../../redux/feature/SessionSlice';
import { useAppDispatch, useAppSelector } from '../../../../helpers/hooks';
import TagManager from 'react-gtm-module';

interface PaymentSuccessProps {
  isTrial?: boolean;
}

const PaymentSuccess: React.FC<PaymentSuccessProps> = ({ isTrial = false }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const stripeSessionId = useAppSelector(state => state.stripe.stripeSessionId);

  useLayoutEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'checkout_end',
        checkout_session_id: stripeSessionId,
        success: true,
      },
    });

    // Make sure to track the trial conversions via meta pixel
    if (isTrial) {
      // Track the trial conversion
      window.fbq('track', 'StartTrial', {
        value: 1.0, // if your trial has a value
        currency: 'USD', // your currency
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.history.pushState(null, '', window.location.href);

    window.addEventListener('popstate', () => {
      navigate('/home');
    });

    return () => {
      window.removeEventListener('popstate', () => {
        navigate('/home');
      });
    };
  }, [navigate]);

  return (
    <div className="flex flex-col items-center justify-center mx-4">
      <Icons.Check className="w-16 h-16 mb-6" />
      <h1 className="text-lg text-center md:text-xl font-semibold mb-1">
        You have subscribed successfully
      </h1>
      <p className="text-sm md:text-base text-center mb-7">
        Thank you for your purchase. Your transaction was successful.
      </p>
      <button
        onClick={() => {
          dispatch(setLisitngId(null));
          dispatch(setSessionID(null));
          navigate('/home');
        }}
        className="bg-[#00856D] text-white px-8 py-2 rounded-lg w-full md:max-w-[198px]"
      >
        Create New Listing
      </button>
    </div>
  );
};

export default PaymentSuccess;
