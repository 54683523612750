import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSignIn } from '@clerk/clerk-react';

import { InputField } from '../../components/shared/ui/InputField/index';
import { Icons } from '../../helpers/icons';
import { Button } from '../../components/shared/ui/Button';
import { forgetResolver } from '../../validators/AuthValidators';

interface ForgetPros {}
interface FormValues {
  email: string;
}

const Login: React.FC<ForgetPros> = props => {
  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
    setValue,
    trigger,
    watch,
    clearErrors,
  } = useForm<FormValues>({ resolver: forgetResolver, mode: 'onChange' });

  const [debouncedEmail, setDebouncedEmail] = useState<string>('');
  const [showErrors, setShowErrors] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const navigate = useNavigate();
  const { signIn } = useSignIn();

  const formValues = watch();

  const handleInputChange = (field: keyof FormValues, value: string) => {
    setValue(field, value, { shouldValidate: true });
    setDebouncedEmail(value);
    setShowErrors(false);
    clearErrors(field);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (debouncedEmail) {
        trigger('email').then(() => {
          setShowErrors(true);
        });
      }
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [debouncedEmail, trigger]);

  const handleReset = async () => {
    try {
      await signIn
        ?.create({
          strategy: 'reset_password_email_code',
          identifier: formValues.email,
        })
        .then(_ => {
          navigate('/verify-otp', { state: { email: formValues.email } });
        });
    } catch (err: any) {
      const error =
        err.errors?.[0]?.message ||
        'Failed to send reset code. Please try again.';
      setErrorMessage(error);
      console.error('Failed to send reset code:', error);
    }
  };

  return (
    <div className="content-container bg-[#F9F9F9]">
      <div className="bg-white px-4 py-6 md:p-8 my-2 md:w-[1000px] mx-5 rounded-lg shadow-md max-w-xl border border-[#C9CBCD]">
        <h2 className="text-xl md:text-3xl font-bold md:mt-10 mb-1 tracking-wider">
          Forgot Password ?
        </h2>
        <h1 className="text-[#3F3F3F md:text-base text-sm mb-6 mt-4">
          Please enter associated email with your account.
        </h1>
        <form onSubmit={handleSubmit(handleReset)}>
          <div className="space-y-3">
            <InputField
              errorMsg={showErrors ? errors.email?.message : ''}
              register={register}
              name="email"
              placeholder="Ex.email@domain.com"
              Icon={Icons.Mail}
              onChange={e => handleInputChange('email', e.target.value)}
            />
          </div>
          {errorMessage && <p className="text-red-500 mt-3">{errorMessage}</p>}
          <div className="mt-20 mb-40">
            <Button
              btnType="signUp"
              disabled={!isValid}
              fullWidth
              variant="primary"
              label="Send One-Time Password"
              size="xl"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
