import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Images } from '../../../../helpers/images';
import { Icons } from '../../../../helpers/icons';
import Sidebar from '../SideBar';
import { useAuth } from '@clerk/clerk-react';

const Header: React.FC = () => {
  const location = useLocation();
  const { isSignedIn } = useAuth();
  const [isSideBarOpen, setSideBarOpen] = useState<boolean>(false);

  const desktopHeaderPaths = [
    '/login',
    '/signup',
    '/stripe-success',
    '/stripe-cancel',
    '/trial',
    '/profile',
  ];
  const isDesktopHeader = desktopHeaderPaths.includes(location.pathname);

  const handleSidebarClose = () => {
    setSideBarOpen(false);
  };

  return (
    <>
      <header
        className={`flex justify-between items-center p-3.5 bg-white drop-shadow-md ${
          isDesktopHeader ? 'md:block' : 'md:hidden'
        }`}
      >
        <div className="flex-1"></div>
        <div className="flex-1 flex justify-center">
          {isDesktopHeader ? (
            <img
              src={Images.headerLogo}
              alt="Logo"
              className="md:h-12 h-10 cursor-pointer"
            />
          ) : (
            <Link to="/home">
              <img
                src={Images.headerLogo}
                alt="Logo"
                className="md:h-12 h-10 cursor-pointer"
              />
            </Link>
          )}
        </div>
        <div className="flex-1 flex justify-end md:hidden">
          {isSignedIn ? (
            <div
              className="cursor-pointer"
              onClick={() => setSideBarOpen(true)}
            >
              <Icons.Hamburger />
            </div>
          ) : (
            <div className="w-6 h-6" />
          )}
        </div>
      </header>
      {isSideBarOpen && (
        <Sidebar isMobile={true} onClose={handleSidebarClose} />
      )}
    </>
  );
};

export default Header;
