import React, { useState } from 'react';

import Card from '../../components/shared/ui/Card';
import { Icons } from '../../helpers/icons';
import { faqMock } from '../../common/__mocks__/faqMock';

interface ExpandableCardProps {
  item: { heading: string; content: string };
}

const ExpandableCard = (props: ExpandableCardProps) => {
  const { item } = props;
  const { heading, content } = item;

  const [isExpanded, setExpanded] = useState<boolean>(false);

  const toggleExpanded = () => {
    setExpanded(!isExpanded);
  };

  return (
    <div className="md:w-full border shadow-[0px_0px_12px_0px_rgba(74,131,184,0.12)] rounded-[10px] bg-white p-6 mb-4">
      <div className="flex flex-row justify-between items-center">
        <div className="md:text-lg text-base">{heading}</div>
        <div className="py-2 cursor-pointer" onClick={toggleExpanded}>
          {isExpanded ? <Icons.Dash className="mr-1" /> : <Icons.Plus />}
        </div>
      </div>
      {isExpanded && (
        <div className="mt-4 pt-4 border-t md:text-base text-sm">{content}</div>
      )}
    </div>
  );
};

const FAQ = () => {
  return (
    <>
      <Card
        heading="FAQ"
        subHeading="Frequently Asked Questions"
        className="w-full h-fit mb-5 md:mb-10"
      >
        {faqMock.map((item, index) => (
          <ExpandableCard item={item} key={index} />
        ))}
      </Card>
    </>
  );
};

export default FAQ;
