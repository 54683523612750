import { Route, Routes, Navigate } from 'react-router-dom';
import SignUp from '../container/SignUp';
import Login from '../container/Login';
import ResetPassword from '../container/ResetPassword';
import VerifyOTP from '../container/VerifyOTP';

import EmailSent from '../container/SignUp/EmailSent';
import VerifyEmail from '../container/SignUp/EmailVerification';
import CreatePassword from '../container/CreatePassword';
import MainLayout from '../components/shared/layout/MainLayout';

const PublicRoutes: React.FC = () => {
  return (
    <MainLayout isSideBar={false}>
      <Routes>
        <Route path="/signUp" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/email-sent" element={<EmailSent />} />
        <Route path="/verification" element={<VerifyEmail />} />
        <Route path="/new-password" element={<CreatePassword />} />
        <Route path="/verify-otp" element={<VerifyOTP />} />
        <Route path="/" element={<Navigate to="/login" replace />} />
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    </MainLayout>
  );
};

export default PublicRoutes;
