import logo from '../assets/images/logo.svg';
import userDefault from '../assets/images/userDefault.svg';
import google from '../assets/images/google.svg';
import headerLogo from '../assets/images/etSEO-Title-Logo-V2.png';

const Images = {
  logo,
  userDefault,
  google,
  headerLogo,
};

export { Images };
