import { useQuery, UseQueryResult, UseQueryOptions } from 'react-query';
import { AxiosInstance } from 'axios';

const useGenericGetQuery = <TResponse, TError = unknown>(
  endpoint: string | null,
  axiosInstance: AxiosInstance,
  params?: Record<string, any>,
  headers?: Record<string, string>,
  options?: UseQueryOptions<TResponse, TError>
): UseQueryResult<TResponse, TError> => {
  const fetcher = async (): Promise<TResponse> => {
    if (!endpoint) {
      return Promise.reject('No endpoint provided');
    }
    try {
      const response = await axiosInstance.request<TResponse>({
        url: endpoint,
        method: 'GET',
        params,
        headers,
      });
      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch data');
    }
  };

  return useQuery<TResponse, TError>([endpoint, params, headers], fetcher, {
    enabled: !!endpoint,
    ...options,
  });
};

export default useGenericGetQuery;
