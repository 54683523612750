import { useState, useCallback } from 'react';

interface AlertProps {
  isSuccess: boolean;
  message: string;
}

const useAlert = () => {
  const [alert, setAlert] = useState<AlertProps | null>(null);

  const closeAlert = useCallback(() => setAlert(null), []);

  return { alert, setAlert, closeAlert };
};

export default useAlert;
