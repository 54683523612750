import { ReactNode } from 'react';
import RModal from 'react-modal';

import './index.css'

RModal.setAppElement('#root');

interface ModalProps {
  isOpen: boolean;
  closeModal: ()=>void;
  children?: ReactNode;
  className?: string;
}

const Modal = ({isOpen, closeModal, children, className}: ModalProps)=>{
  return (
    <RModal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Example Modal"
      className={`p-4 ${className}`}
    >
      {children}
    </RModal>
  )
}

export default Modal;