import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import authReducer from './feature/AuthSlice';
import sessionReducer from './feature/SessionSlice';
import stripeReducer from './feature/StripeSlice';
import listingReducer from './feature/ListingSlice';
import keywordsReducer from './feature/KeywordsSlice';
import historyEnableReducer from './feature/HistoryEnableSlice';
import checkOutReducer from './feature/CheckOutSlice';

const authPersistConfig = {
  key: 'root',
  storage,
};

const sessionPersistConfig = {
  key: 'session',
  storage,
};

const sessionStripeConfig = {
  key: 'stripe',
  storage,
};

const listingPersistConfig = {
  key: 'listing',
  storage,
};
const keywordsPersistConfig = {
  key: 'keywords',
  storage,
};

const historyEnablePersistConfig = {
  key: 'historyEnable',
  storage,
};

const checkOutPersistConfig = {
  key: 'checkOut',
  storage,
};

const persistedAuthReducer = persistReducer(authPersistConfig, authReducer);
const persistedSessionReducer = persistReducer(
  sessionPersistConfig,
  sessionReducer
);
const persistedStripeReducer = persistReducer(
  sessionStripeConfig,
  stripeReducer
);
const persistedListingReducer = persistReducer(
  listingPersistConfig,
  listingReducer
);
const persistedKeywordsReducer = persistReducer(
  keywordsPersistConfig,
  keywordsReducer
);
const persistedHistoryEnableReducer = persistReducer(
  historyEnablePersistConfig,
  historyEnableReducer
);

const persistedCheckOutReducer = persistReducer(
  checkOutPersistConfig,
  checkOutReducer
);
export const store = configureStore({
  reducer: {
    auth: persistedAuthReducer,
    session: persistedSessionReducer,
    stripe: persistedStripeReducer,
    listing: persistedListingReducer,
    keywords: persistedKeywordsReducer,
    historyEnable: persistedHistoryEnableReducer,
    checkOut: persistedCheckOutReducer,
  },
});
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
