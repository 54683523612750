import { useRef } from 'react';
import { Icons } from '../../../../helpers/icons';

interface AvatarUploadProps {
  currentImage?: string;
  selectedImage: File | null;
  setSelectedImage: (value: any) => void;
}

const AvatarUpload = (props: AvatarUploadProps) => {
  const { currentImage, selectedImage, setSelectedImage } = props;
  const hiddenFileInput = useRef<any>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event: any) => {
    const fileUploaded = event.target.files[0];
    setSelectedImage(fileUploaded);
  };

  return (
    <figure className="w-[140px] h-[140px]">
      <img
        className="rounded-full object-cover w-[140px] h-[140px] shadow-[0px_0px_14px_0px_rgba(0, 0, 0,0.25)]"
        src={selectedImage ? URL.createObjectURL(selectedImage) : currentImage}
        height={140}
        width={140}
        alt={'placeholder'}
      />
      <div className="relative">
        <div
          className="absolute bottom-0 right-0 cursor-pointer bg-white rounded-2xl flex justify-center items-center h-[34px] w-[34px] shadow-xl"
          onClick={handleClick}
        >
          <Icons.Edit />
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={handleChange}
            className="hidden"
            accept="image/png, image/jpeg, image/jpg"
          />
        </div>
      </div>
    </figure>
  );
};

export default AvatarUpload;
