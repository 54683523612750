import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ListingState {
  listingId: string | null;
}

const initialState: ListingState = {
  listingId: null,
};

const listingSlice = createSlice({
  name: 'listing',
  initialState,
  reducers: {
    setLisitngId: (state, action: PayloadAction<string | null>) => {
      state.listingId = action.payload;
    },
  },
});

export const { setLisitngId } = listingSlice.actions;
export default listingSlice.reducer;
