import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SessionState {
  sessionID: string | null;
}

const initialState: SessionState = {
  sessionID: null,
};

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setSessionID: (state, action: PayloadAction<string | null>) => {
      state.sessionID = action.payload;
    },
  },
});

export const { setSessionID } = sessionSlice.actions;
export default sessionSlice.reducer;
