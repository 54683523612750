export const faqMock = [
  {
    heading: 'What is the EtSeo Chatbot?',
    content:
      'Etseo is an AI-powered chatbot designed to help Etsy sellers create optimized and effective titles for their product listings. By inputting details about your product, Etseo generates title suggestions to enhance visibility and attract potential buyers.',
  },
  {
    heading: 'How do I use Etseo?',
    content:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Provident ratione quam nisi ea temporibus, voluptates laboriosam corporis. Neque ratione, accusantium quis possimus enim iste? Aut alias est iusto architecto totam porro molestiae eveniet, inventore sed facere. Nobis fugiat ipsa, cumque iusto possimus consectetur molestiae ut recusandae officia dolorem, asperiores repellendus.',
  },
  {
    heading: 'Are the titles generated by Etseo SEO-friendly?',
    content:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Provident ratione quam nisi ea temporibus, voluptates laboriosam corporis. Neque ratione, accusantium quis possimus enim iste? Aut alias est iusto architecto totam porro molestiae eveniet, inventore sed facere. Nobis fugiat ipsa, cumque iusto possimus consectetur molestiae ut recusandae officia dolorem, asperiores repellendus.',
  },
  {
    heading: 'Can I customize the titles suggested by Etseo?',
    content:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Provident ratione quam nisi ea temporibus, voluptates laboriosam corporis. Neque ratione, accusantium quis possimus enim iste? Aut alias est iusto architecto totam porro molestiae eveniet, inventore sed facere. Nobis fugiat ipsa, cumque iusto possimus consectetur molestiae ut recusandae officia dolorem, asperiores repellendus.',
  },
  {
    heading:
      'Is there a limit to the number of titles I can generate with Etseo?',
    content:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Provident ratione quam nisi ea temporibus, voluptates laboriosam corporis. Neque ratione, accusantium quis possimus enim iste? Aut alias est iusto architecto totam porro molestiae eveniet, inventore sed facere. Nobis fugiat ipsa, cumque iusto possimus consectetur molestiae ut recusandae officia dolorem, asperiores repellendus.',
  },
];
