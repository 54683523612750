import React from 'react';
import { Icons } from '../../../../helpers/icons';
import { useNavigate } from 'react-router-dom';

const BackToHomeButton = () => {
  const navigate = useNavigate();

  return (
    <div
      className="flex flex-row items-center my-5 cursor-pointer md:ml-8 ml-3"
      onClick={() => navigate('/home')}
    >
      <Icons.Back width={20} className="mr-2" />
      <div className="text-sm font-medium text-gray-400">Back to Home</div>
    </div>
  );
};

export default BackToHomeButton;
